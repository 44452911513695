import { Button, Space } from "antd"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';
import Tabela from "../../../../components/template/Tabela"
import { useGeral } from "../../../../contexts/geral";
import { copyToClipboard, downloadToCsv, formatDecimal, printDiv } from "../../../../utils/functions"
import { columnsOrdemProducaoItem } from "./columnsOrdemProducaoItemV2";
import isPermitted from "../../../../hooks/isPermitted";

export function OrdemProducaoItens(props: any) {
  const { userTenant } = useGeral();
  const colors = userTenant.tenant.colors.split(',')

  const isPermittedVisualizarPrecosProdutos = isPermitted('visualizar_precos_produtos')
  const isPermittedVisualizarNomesProdutos = isPermitted('visualizar_nomes_produtos')

  const { ordemProducao } = props
  // console.log(ordemProducao)
  if (!ordemProducao) { return <div>Sem Ordem de Producao</div> }
  const ordemproducaoitens = ordemProducao.ordemproducaoitens
  if (!ordemproducaoitens) { return <div>Sem OrdemProducaoItens</div> }
  // console.log('fichaTecnica',fichaTecnica)
  const dadosFlat: any = []
  let totalCmc = 0.0
  ordemproducaoitens.forEach((it: any) => {
    totalCmc += it.cmcTotal
    const item = {
      id: it.id,
      codigo: it.produto.codigo,
      descricao: it.produto.descricao,
      nomeComponente: it.produto.nomeComponente,
      unidade: it.produto.unidade,
      nomeDeposito: it.produto.deposito.nome,
      quantConsumida: it.quantConsumida,
      estoqueDisponivel: it.estoqueDisponivel,
      cmcUnitario: it.cmcUnitario,
      cmcTotal: it.cmcTotal,
      footer: false,
      addClass: 
        it.estoqueDisponivel < it.quantConsumida ? "bg-[#FFEEEE] " : ""
    }
    dadosFlat.push(item)
    // dadosFlat.push(it)
  })
  const total = {
    id: '#',
    codigo: 'Total',
    descricao: '',
    nomeComponente: '',
    unidade: '',
    nomeDeposito: '',
    quantConsumida: '',
    estoqueDisponivel: '',
    cmcUnitario: totalCmc/ordemProducao.quantProduzida,
    cmcTotal: totalCmc,
    footer: true,
  }
  
  dadosFlat.push(total)

  const colunas = columnsOrdemProducaoItem.slice()
  if (!isPermittedVisualizarPrecosProdutos) {
    colunas.splice(7, 2)
  }
  if (!isPermittedVisualizarNomesProdutos) {
    colunas[2].dataIndex='nomeComponente'
  }
  const nomeProduto = isPermittedVisualizarNomesProdutos ? ordemProducao.fichatecnica.produto.descricao : ordemProducao.fichatecnica.produto.nomeComponente
  const wTab = isPermittedVisualizarPrecosProdutos ? 1178 : 1178 - 165
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)`

  return (
    (ordemproducaoitens) ? (
      <>
        <h1 className={`subtitulo text-base font-bold `}>{ordemProducao.fichatecnica.produto.codigo} - {nomeProduto}</h1>
        <div className="flex flex-col justify-between" style={{ height: "calc(100vh - 137px)" }}>
          {/* <Tabela className="mx-0" id='fichatecnicaitem' titulo={ordemProducao.codigo + " - " + ordemProducao.descricao} subtitulo={ordemproducaoitens.nome} wTab={wTab} htb={htb} hsc={hsc} dataSource={dadosFlat} columns={columnsOrdemProducaoItem} handleRow={handleRowOrdemProducaoItem} rowSelected={rowOrdemProducaoItemSelected} lastSelected={lastOrdemProducaoItemSelected} loading={loadingOrdemProducaoItem} /> */}
          <Tabela className="mx-0" id='fichatecnicaitem' titulo={`${ordemProducao.fichatecnica.produto.codigo} - ${nomeProduto}`} subtitulo={`OP: ${ordemProducao.numero} - Quantidade produzida: ${ordemProducao.quantProduzida} ${ordemProducao.fichatecnica.produto.unidade} - ${ordemProducao.fichatecnica.nome}`} wTab={wTab} htb={htb} hsc={hsc} dataSource={dadosFlat} columns={colunas} handleRow={() => {}} />
          <div className={"pageButtons"} style={{ backgroundColor: colors[3] }}>
            <Space size={10}>
              <Button onClick={() => printDiv('printableDiv-fichatecnicaitem', 'fichatecnicaitem')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
              <Button onClick={() => copyToClipboard('fichatecnicaitem')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
              <Button onClick={() => downloadToCsv('fichatecnicaitem', "Inquilinos.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
              {/* <Button onClick={novaOrdemProducaoItem} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button> */}
            </Space>
          </div>
        </div>
      </>
    ) : (
      null
    )
  )

}