import axios from 'axios';

export const apierp = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*', // CORS
  },
  // baseURL: 'http://localhost:2300',
  // baseURL: 'https://cb23api.mycolorbrilho.com.br',
  timeout: 61000,
});

// export default api;
