import { dateMySqlToPtBr } from "../../../../utils/dateFunctions";
import { formatDecimal } from "../../../../utils/functions";


export const columnsMovimentoEstoque = [
  {
    title: 'Item',
    dataIndex: '',
    ellipsis: true,
    width: '60px',
    align: 'right' as 'right',
    className: 'text-right',
    render: (text: any, record: any, index: any) => `${index + 1}`
  },
  {
    title: 'Data',
    dataIndex: 'data',
    width: '100px',
    className: 'text-left',
    ellipsis: true,
    render: (text: any, record: any) => `${dateMySqlToPtBr(text)}`
  },
  {
    title: 'Tipo Movimento',
    dataIndex: 'tipomovimentoNome',
    width: '250px',
    className: 'text-left',
    ellipsis: true,
    render: (text: any, record: any) => `${text}`
  },
  {
    title: 'Quantidade',
    dataIndex: 'quantidade',
    ellipsis: true,
    width: '120px',
    align: 'right' as 'right',
    className: 'text-right',
    onCell: (record: any, i: any) => ({ className: record.movimento === 2  ? "text-red-600" : "text-blue-600" }),
    render: (text: any, record: any) => `${formatDecimal(+text, 6)}`
  },
  {
    title: 'VlrUnitario',
    dataIndex: 'vlrUnitario',
    ellipsis: true,
    width: '100px',
    align: 'right' as 'right',
    className: 'text-right',
    onCell: (record: any, i: any) => ({ className: record.movimento === 2 ? "text-red-600" : "text-blue-600" }),
    render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
  },
  {
    title: 'Saldo',
    dataIndex: 'saldoAcumulado',
    ellipsis: true,
    width: '150px',
    align: 'right' as 'right',
    className: 'text-right',
    onCell: (record: any, i: any) => ({ className: record.saldoAcumulado < 0 ? "text-red-600" : "text-blue-600" }),
    render: (text: any, record: any) => `${formatDecimal(+text, 6)}`
  },
  {
    title: 'CmcUnitario',
    dataIndex: 'cmcUnitario',
    ellipsis: true,
    width: '100px',
    align: 'right' as 'right',
    className: 'text-right',
    render: (text: any, record: any) => `${formatDecimal(+text, 6)}`
  },
  {
    title: 'CmcTotal',
    dataIndex: 'cmcTotal',
    ellipsis: true,
    width: '100px',
    align: 'right' as 'right',
    className: 'text-right',
    onCell: (record: any, i: any) => ({ className: record.cmcTotal < 0 ? "text-red-600" : "text-blue-600" }),
    render: (text: any, record: any) => `${formatDecimal(+text, 2)}`
  },
  // {
  //   title: 'NotaFiscal',
  //   dataIndex: 'notaFiscal',
  //   width: '100px',
  //   className: 'text-left',
  //   ellipsis: true,
  //   render: (text: any, record: any) => `${text}`
  // },
  {
    title: 'Operação',
    dataIndex: 'operacao',
    width: '400px',
    className: 'text-left',
    ellipsis: true,
    render: (text: any, record: any) => `${text}`
  },
  {
    title: 'Motivo',
    dataIndex: 'motivo',
    width: '200px',
    className: 'text-left',
    ellipsis: true,
    render: (text: any, record: any) => `${text ? text : ''}`
  },
  {
    title: 'Observação',
    dataIndex: 'observacao',
    width: '200px',
    className: 'text-left',
    ellipsis: true,
    render: (text: any, record: any) => `${text ? text : ''}`
  },
  {
    title: 'Operador',
    dataIndex: 'updatedBy',
    width: '150px',
    className: 'text-left',
    ellipsis: true,
    render: (text: any, record: any) => `${text ? text : ''}`
  },



]