import { useEffect, useState } from "react"
import { LockFilled, StarOutlined, StarFilled, UnlockFilled, AndroidOutlined, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, message, Popconfirm, Radio, Table, Typography } from 'antd';
import { Drawer, Form, Button, Col, Row, Input, Select, Space } from 'antd';
import { copyToClipboard, downloadToCsv, groupBy, printDiv } from "../../utils/functions";
import { customMessage } from "../../components/template/CustomMessage";

import { useAuth } from '../../contexts/auth';
import { apierp } from "../../services/api";

import { useNavigate } from "react-router-dom";
import Layout from "../../components/template/Layout";
import Spinner from "../../components/template/Spinner";
import { useGeral } from "../../contexts/geral";

const { Search } = Input;
const { Title } = Typography;

export default function Role() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  // console.log('usuario',usuario)
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)
  let navigate = useNavigate();
  if (menu.url !== '/roles') navigate('/')

  const { Option } = Select;
  const [form] = Form.useForm();
  const [formMenu] = Form.useForm();
  const [formPermission] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerMenuVisible, setDrawerMenuVisible] = useState(false)
  const [drawerPermissionVisible, setDrawerPermissionVisible] = useState(false)
  const [toggleState, setToggleState] = useState(false)
  
  const [rowSelected, setRowSelected] = useState(-1)
  const [lastSelected, setLastSelected] = useState(-1)
  const [isInclusao, setIsInclusao] = useState(false)
  const [roleSelected, setRoleSelected] = useState('')

  const [roles, setRoles] = useState([])
  const [role, setRole] = useState(null as any)
  const [roleBlocked, setRoleBlocked] = useState(true)
  const [roleBlocked2, setRoleBlocked2] = useState(true)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [menus, setMenus] = useState([])
  const [permissions, setPermissions] = useState([])
  const [groupsPermissions, setGroupsPermissions] = useState([])
  
  const [menusRole, setMenusRole] = useState([] as number[])
  const [permissionsRole, setPermissionsRole] = useState([] as number[])
  const [checkMenus, setCheckMenus] = useState([])

  const descSecurityLevel = ['root', 'master', 'admin', 'manager-0', 'manager-1', 'manager-2', 'user-nivel-6', 'user-nivel-7', 'user-nivel-8', 'user-nivel-9' ];
  const secLevel = descSecurityLevel.map((x: any, i) => { return ({level: i, desc: x})})
  useEffect(() => {
    setLoading(true)
    setTimeout(async function(){ 
      try{
        await loadRoles() 
        await loadMenus() 
        await loadPermissions() 
      } catch(err) {
        if (customMessage(err)===403) Logout()
      }
      setLoading(false)
    }, 500);
  }, [toggleState]);

  async function loadRoles() {
      const response = await apierp.get('rolesbt')
      const data = response.data as any;
      
      // data.forEach((x: any) => {
      //   x.selected = ''
      // })
      setRoles(data);
  }
  
  async function loadMenus() {
    if (menus.length > 0) return
    const response = await apierp.get('menushgtsl')
    const data = response.data as any;
    // console.log('menus', data, usuario.topSecurityLevel)
    setMenus(data.filter((x: any) => x.securityLevel >= usuario.topSecurityLevel));
  }

  async function loadPermissions() {
    if (permissions.length > 0) return
      const response = await apierp.get('permissions')
      const data = response.data as any;
      const grupos = await groupBy(data, ['group'], ['id'])
      // console.log('permissions', data)
      setPermissions(data);
      setGroupsPermissions(grupos)
  }
  async function loadRole(id: number) {
    try {
      const response = await apierp.get(`rolesxd/${id}`)
      const data = response.data as any;
      const menusRole: [] = data.menus.map((x: any) => x.id)
      setMenusRole(menusRole);
      const permissionsRole: [] = data.permissions.map((x: any) => x.id)
      setPermissionsRole(permissionsRole);
    } catch (err) {
      if (customMessage(err)===403) Logout()
    }
  }

  async function handleRow(record: any, index: any) {
    setLastSelected(index)
    const block = ['root','master','admin']
    const isBlocked2 = block.findIndex((x) => x === record.slug) >= 0
    const isBlocked = false // record.isImmutable 
    if (rowSelected === index) {
      setRowSelected(-1)
      setRoleBlocked(true)
      setRoleBlocked2(true)
    } else {
      setRoleSelected(record.name)
      setRowSelected(index)
      setRoleBlocked(isBlocked)
      setRoleBlocked2(isBlocked2)
      setIsInclusao(false)
      showDrawer()
      const roley: any = {
        id: record.id,
        // tenantId: record.tenantId,
        name: record.name,
        description: record.description,
        slug: record.slug,
        securityLevel: record.securityLevel
      }
      fillForm(roley)
      await loadRole(record.id)
    }
  }

  const showDrawer = () => {
    setButtonsDisabled(false)
    setDrawerVisible(true)
  };

  const showDrawerMenu = () => {
    setButtonsDisabled(false)
    setDrawerMenuVisible(true)
  };
  
  const showDrawerPermission = () => {
    setButtonsDisabled(false)
    setDrawerPermissionVisible(true)
  };

  const hideDrawer = () => {
    setButtonsDisabled(false)
    setRowSelected(-1)
    setDrawerVisible(false)
  };

  const hideDrawerMenu = () => {
    setButtonsDisabled(false)
    setDrawerMenuVisible(false)
  };

  const hideDrawerPermission = () => {
    setButtonsDisabled(false)
    setDrawerPermissionVisible(false)
  };

  async function handleSubmit(dados: any){
    setButtonsDisabled(true)
    dados.name = dados.name.trim()
    dados.slug = dados.slug.toLowerCase().trim().replaceAll(' ', '')
    if (dados.id ==='') {
      //CREATE
      try {
        const result = await apierp.post('roles', dados);
        setToggleState(!toggleState)
        message.success('Perfil incluido com sucesso!');
        hideDrawer()
      } catch (err) {
        if (customMessage(err)===403) Logout()
      }
    } else {
      //UPDATE
      try {
        const result = await apierp.put(`roles/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Perfil alterado com sucesso!');
        hideDrawer()
      } catch (err) {
        if (customMessage(err)===403) Logout()
      }
    }

  };

  async function handleDelete(){
    const dados = form.getFieldsValue()
    try {
      await apierp.delete(`roles/${dados.id}`);
      setToggleState(!toggleState)
      setLastSelected(-1)
      message.success('Perfil excluido com sucesso!');
      hideDrawer()
    } catch (err) {
      if (customMessage(err)===403) Logout()
    }

  };

  function confirmDelete(e: any) {
    handleDelete();
  }
  
  function cancelDelete(e: any) {
    message.error('Perfil não foi excluído');
  }

  const novaRole= () => {
    form.resetFields();
    setIsInclusao(true)
    setRoleBlocked(false)
    setRoleBlocked2(false)
    showDrawer()
    form.setFieldsValue({ id: ''})
    // const roley: any = {
    //   id: '',
    //   tenantId: usuario.tenantId,
    //   name: '',
    //   description: '',
    //   slug: '',
    //   securityLevel: '',
    // }
    // fillForm(roley)
  }

  const fillForm = (roley: any) => {
    form.setFieldsValue({
      id: roley.id,
      // tenantId: roley.tenantId,
      name: roley.name,
      description: roley.description,
      slug: roley.slug,
      securityLevel: roley.securityLevel,
    });
  };

  const resetForm = () => {
    setRowSelected(-1)
    novaRole() // form.resetFields();
  };

  async function handleSubmitMenu() {
    setButtonsDisabled(true)
    const roleSelected: any=roles[rowSelected]
    const result = await apierp.post(`rolesacl/${roleSelected.id}`, {menus: menusRole})
    message.success('Menu do Perfil atualizado com sucesso!');
    hideDrawerMenu()
  }

  function onCheckMenu(e: any, id: number) {
    const novo = menusRole.map((x: any) => +x)
    
    if (e.target.checked) {
      // adiciona id em menusRoles
      novo.push(id)
      setMenusRole(novo)
    } else {
      //remove id de menusRoles
      const index = menusRole.indexOf(id);
      if (index > -1) {
        novo.splice(index, 1); // 2nd parameter means remove one item only
        setMenusRole(novo)
      }
    }
  }

  async function handleSubmitPermission() {
    setButtonsDisabled(true)
    const roleSelected: any=roles[rowSelected]
    const result = await apierp.post(`rolesacl/${roleSelected.id}`, {permissions: permissionsRole})
    message.success('Permissões do Perfil atualizado com sucesso!');
    hideDrawerPermission()
  }

  function onCheckPermission(e: any, id: number) {
    const novo = permissionsRole.map((x: any) => +x)
    
    if (e.target.checked) {
      // adiciona id em menusRoles
      novo.push(id)
      setPermissionsRole(novo)
    } else {
      //remove id de menusRoles
      const index = permissionsRole.indexOf(id);
      if (index > -1) {
        novo.splice(index, 1); // 2nd parameter means remove one item only
        setPermissionsRole(novo)
      }
    }
  }

  const columns = [
    {
      title: 'Id',
      dataIndex:'id',
      width: '50px',
      className: 'text-right',
      ellipsis: true,
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      width: '215px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      width: '215px',
      className: 'text-left',
      ellipsis: true,
      render : (text: any, record:any ) => `(${record.tenantId}) ${text}`
      // render : (text: any, record:any ) => `${text} (${record.tenantId}) `
      // render : (text: any, record:any ) => `<div className="flex"> <div>${text}</div></div>(${record.tenantId})<div> </div>`
      // render(text: any, record: any, i: any) {
      //   return {
      //     children: (<div className="flex justify-content-between"><div>{text}</div><div>[{record.tenantId}]</div></div>)
      //   }
      // }
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      width: '140px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'Sec Level',
      dataIndex: 'securityLevel',
      width: '120px',
      className: 'text-left',
      ellipsis: true,
      render : (text: any, record:any ) => `(${text}) ${secLevel[text].desc}`
    },
  ]

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-perfil', 'perfil')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('perfil')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('perfil', "Perfis.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        <Button onClick={() => novaRole()} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button>
      </Space>
    )
  }

  const wTab = 757 // liquido = x-17

  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb

  return (
    <Layout titulo={menu.label} botoes={botoes()}>
      <div className="spinner mt-5 justify-center items-center " style={{ display: loading ? "flex" : "none" }} >
        <Spinner />
      </div>
      <div className="bg-blue-30 mx-5 overflow-x-auto">
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          <div id="printableDiv-perfil" className="pt-6  pb-[1px] fadein" style={{ display: loading ? "none" : "" }}>
            <h1 className='hidden mb-1 text-2xl font-bold'>Perfis</h1>
            {/* TABLE */}
            <div className="flex flex-column justify-content-between" style={{ maxHeight: htb }}>
              <div className="envelopetable bg-bluegray-50 h-full overflow-y-auto">
                <Table id='perfil' dataSource={roles} columns={columns} size="small" rowKey="id"
                  rowClassName={(record, index) => (index === rowSelected ? "rowSelected" : "")}
                  pagination={false}
                  scroll={{ y: hsc }}
                  onRow={(record: any, rowIndex: any) => {
                    return {
                      onClick: event => { handleRow(record, rowIndex) }, // click row
                      className: lastSelected === rowIndex ? "lastSelected" : ""
                    };
                  }}
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* DRAWER FORM */}
      <Drawer
        title={ (isInclusao ? "Criar um novo" : "Alterar") + " Perfil"}
        width={360}
        closable={false}
        onClose={hideDrawer}
        visible={drawerVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={form} onFinish={handleSubmit} >
            <Title level={5}>{form.getFieldValue('name')}</Title>
            <Form.Item name="id" hidden noStyle></Form.Item>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, message: 'Entre com Nome do perfil' }]}
                >
                  <Input placeholder="Nome do perfil" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Descrição"
                  rules={[{ required: true, message: 'Entre com uma Descrição do perfil' }]}
                >
                  <Input placeholder="Descrição do perfil" />
                </Form.Item>
              </Col>
            </Row>

            {/* SLUG - TODO - RETIRAR E REFATORAR TUDO ONDE ESTIVER */}
            <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                  name="slug"
                  label="Slug (nome interno)"
                  rules={[{ required: true, message: 'Entre com Slug do perfil' }]}
                >
                  <Input placeholder="Slug do perfil" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="securityLevel"
                  label="Nível de Segurança"
                  rules={[{ required: true, message: 'Selecione Nível de segurança' }]}
                >
                  <Select placeholder="Selecione um Nível de segurança">
                    <Option value={''}>Selecione um Nível de segurança</Option>
                    {secLevel.filter((x: any, i) => i > 0).map((sl: any, index: any) => {
                      return <Option value={sl.level}>({sl.level}) {sl.desc}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Space className="inner-drawer-buttons">
              <Button className="bg-ant-red-100" onClick={showDrawerMenu} disabled={isInclusao }>Menus</Button>
              <Button className="bg-gray-100 rounded-full" onClick={showDrawerPermission} disabled={isInclusao}>Permissões</Button>
            </Space>
          </Form>
        </div>
        
        <Space className="drawer-footer">
          <Space>
            <Button onClick={hideDrawer}>Cancel</Button>
            {/* <Button onClick={resetForm}>Reset</Button> */}
            {/* <Button className="bg-ant-blue-100" onClick={showDrawerMenu} disabled={isInclusao }>Menus</Button>
            <Button className="bg-ant-blue-100" onClick={showDrawerPermission} disabled={isInclusao}>Permissões</Button> */}
            <Popconfirm disabled={ roleBlocked2 }
              title="Deseja realmente excluir este perfil?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => {}} type="primary" danger disabled={isInclusao || roleBlocked2 || buttonsDisabled} >Delete</Button>
            </Popconfirm>

            <Button onClick={form.submit} disabled={roleBlocked2 || buttonsDisabled} type="primary">Submit</Button>
          </Space>
        </Space>
        <Drawer
            title="Menus"
            width={360}
            closable={false}
            onClose={hideDrawerMenu}
            visible={drawerMenuVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
          >
            <div className="inner-drawer-body" >
              <Form layout="vertical" form={formMenu} onFinish={handleSubmitMenu} >
                <Title level={5}>{form.getFieldValue('name')}</Title>
                {menus.map((it0: any) => {
                  const link = it0.url ? "link" : "nolink"
                  const classLink = it0.url ? "text-indigo-500" : "text-pink-500"
                  const isChecked = menusRole.findIndex((x) => x === it0.id) >= 0
                  const isDisabled = it0.securityLevel <= usuario.topSecurityLevel
                  const icon = it0.url ? <Checkbox checked={isChecked} disabled={isDisabled} onChange={(e) => onCheckMenu(e, it0.id)} /> : <div className="w1rem"></div>
                  return (
                    <>
                      <div key={it0.id} id={it0.id} data-pai={null} className={"n0 flex " + classLink} >
                        <div className="w-1rem">{icon}</div>
                        <div className={"pl-2 " + classLink}>{it0.label} ({it0.securityLevel})</div>
                      </div>
                      {it0.items && it0.items.map((it1: any) => {
                        const link = it1.url ? "link" : ""
                        const classLink = it1.url ? "text-indigo-500" : "text-pink-500"
                        const isChecked = menusRole.findIndex((x) => x === it1.id) >= 0
                        const isDisabled = it1.securityLevel <= usuario.topSecurityLevel || (it1.securityLevel===2 && roleBlocked)
                        const icon = it1.url ? <Checkbox checked={isChecked} disabled={isDisabled} onChange={(e) => onCheckMenu(e, it1.id)} /> : <div className="w1rem"></div>
                        return (
                          <>
                            <div key={it1.id} id={it1.id} data-pai={it0.id} className={"n1 flex " + classLink} >
                              <div className="w-4rem pl-6">{icon}</div>
                              <div className={"pl-2 " + classLink}>{it1.label} ({it1.securityLevel})</div>
                            </div>
                            {it1.items && it1.items.map((it2: any) => {
                              const link = it2.url ? "link" : ""
                              const classLink = it2.url ? "text-indigo-500" : "text-pink-500"
                              const isChecked = menusRole.findIndex((x) => x === it2.id) >= 0
                              const isDisabled = it2.securityLevel <= usuario.topSecurityLevel
                              const icon = it2.url ? <Checkbox  checked={isChecked} disabled={isDisabled} onChange={(e) => onCheckMenu(e, it2.id)} /> : <div className="w1rem"></div>
                              return (
                                <>
                                  <div key={it2.id} id={it2.id} data-pai={it1.id} className={"n2 flex " + classLink} >
                                    <div className="w-7rem pl-9">{icon}</div>
                                    <div className={"pl-2 " + classLink}>{it2.label} ({it2.securityLevel})</div>
                                  </div>
                                  {it2.items && it2.items.map((it3: any) => {
                                    const link = it3.url ? "link" : ""
                                    const classLink = it3.url ? "text-indigo-500" : "text-pink-500"
                                    const isChecked = menusRole.findIndex((x) => x === it3.id) >= 0
                                    const isDisabled = it3.securityLevel <= usuario.topSecurityLevel
                                    const icon = it3.url ? <Checkbox checked={isChecked} disabled={isDisabled} onChange={(e) => onCheckMenu(e, it3.id)} /> : <div className="w1rem"></div>
                                    return (
                                      <>
                                        <div key={it3.id} id={it3.id} data-pai={it2.id} className={"n3 flex " + classLink} >
                                          <div className="w-10rem pl-12">{icon}</div>
                                          <div className={"pl-2 " + classLink}>{it3.label} ({it3.securityLevel})</div>
                                        </div>
                                      </>
                                    )
                                  })}
                                </>
                              )
                            })}
                          </>
                        )
                      })}
                    </>
                  )
                })}
              </Form>
            </div>
          <Space className="drawer-footer flex w-full justify-end">
            <Space>
              <Button onClick={hideDrawerMenu}>Cancel</Button>
              <Button onClick={formMenu.submit} disabled={(roleBlocked && !roleBlocked || buttonsDisabled)} type="primary">Submit</Button>
            </Space>
          </Space>
        </Drawer>
        <Drawer
            title="Permissões"
            width={360}
            closable={false}
            onClose={hideDrawerPermission}
            visible={drawerPermissionVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
          >
          <div className="inner-drawer-body">
            <Form layout="vertical" form={formPermission} onFinish={handleSubmitPermission} >
              <Title level={5}>{form.getFieldValue('name')}</Title>
              {groupsPermissions.map((gr: any) => {
                return (
                  <>
                    <div key={gr.group} id={gr.group} data-pai={null} className={"n0 text-pink-500"} >
                      <div className="">{gr.group} </div>
                    </div>
                    {permissions.filter((x: any) => x.group === gr.group).map((perm: any) => {
                    const isChecked = permissionsRole.findIndex((x) => x === perm.id) >= 0
                    const icon = <Checkbox checked={isChecked} disabled={perm.securityLevel <= usuario.topSecurityLevel} onChange={(e) => onCheckPermission(e, perm.id)} />
                    return (
                        <div  key={perm.id} id={perm.id} className="flex">
                          <div className="w-2rem pl-3">{icon}</div>
                          <div className="pl-2">{perm.description}</div>
                        </div>
                      )
                    })
                    }
                  </>
                )
              })
              }
            </Form>
          </div>
          <Space className="drawer-footer flex w-full justify-end">
            <Space>
              <Button onClick={hideDrawerPermission}>Cancel</Button>
              <Button onClick={formPermission.submit} disabled={roleBlocked || buttonsDisabled} type="primary">Submit</Button>
            </Space>
          </Space>
        </Drawer>
      </Drawer>
    </Layout>
  )
}
