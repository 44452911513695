
import * as React from "react"
import { useEffect, useState } from "react"

import { Divider, Typography } from "antd";

import Layout from "../../../components/template/Layout"
import { useAuth } from "../../../contexts/auth";
import useWindowSize from "../../../hooks/useWindowSize";
import { useGeral } from "../../../contexts/geral";
import { customMessage } from "../../../components/template/CustomMessage";
import { apierp } from "../../../services/api";
import SpinnerNew from "../../../components/template/SpinnerNew";
import { formatDecimal } from "../../../utils/functions";
import { dateFormat } from "../../../utils/dateFunctions";
import SpinnerModal from "../../../components/template/SpinnerModal";

const { Title, Text, Paragraph } = Typography;

export default function RelCloretoPF() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const [toggleState, setToggleState] = useState(false)
  const [loadingRelCloretoPF, setLoadingRelCloretoPF] = useState(false)
  const [relcloretopf, setRelCloretoPF] = useState([])
  const [estEmProdAcabados, setEstEmProdAcabados] = useState(0)
  const [empresas, setEmpresas] = useState([])

  // useEffect( () => {
  //     try {
  //       loadEmpresas()
  //     } catch (err) {
  //       if (customMessage(err) === 403) Logout()
  //     }
  // }, [])

  useEffect(() => {
    setLoadingRelCloretoPF(true)
    setTimeout(async function () {
      try {
        await loadEmpresas()
        await loadRelCloretoPF()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      setLoadingRelCloretoPF(false)
    }, 500);

  }, [])

  async function loadRelCloretoPF() {
    // console.log('loadRelCloretoPF', relcloretopf)
    const dados = {
      produtoId: 73, // CLORETO DE METILENO
    }
    const response = await apierp.post('relatorios/materiaprima', dados)
    const data = response.data as any;
    // console.log(data)
    setRelCloretoPF(data.relcloretopf);
    setEstEmProdAcabados(data.estEmProdAcabados);
  }

  async function loadEmpresas() {
    // console.log('laodEmpresas')
    const response = await apierp.get('empresas')
    const data = response.data as any;
    // console.log(data)
    setEmpresas(data);
  }

  let saldoant = [15834, 7112]
  let geralAnt = saldoant[0] + saldoant[1]
  let geralAtual = saldoant[0] + saldoant[1] - estEmProdAcabados
  const qtMeses = relcloretopf.length/2
  const dadosrel: any = []
  const now = new Date();
  const mesHoje = dateFormat(now, "yyyy/MM")
  const diaHoje = +dateFormat(now, "dd")
  empresas.forEach((e: any, index) => {
    // console.log('emp', e.id, e.nome, relcloretopf)
    let sldant = saldoant[index]
    relcloretopf.filter((x: any) => x.empresaId === e.id).map((x: any, index) => {
      let sldatu = sldant + x.compra - x.consumo - x.quebra - x.saidatransf + x.entradatransf + x.entradacompra - x.saidacompra
      const mesItem = `${x.ano}/${x.mes.toString().padStart(2, '0')}`
      // console.log(index, sldant, x.compra, x.consumo, sldatu)
      // console.log( mesItem, mesHoje, diaHoje)
      const item = {
        empresaId: x.empresaId,
        ano: x.ano,
        mes: x.mes,
        sldant,
        compra: x.compra + x.entradacompra - x.saidacompra,
        consumo: x.consumo + x.quebra + x.saidatransf - x.entradatransf,
        sldatu,
        naoMostra: mesItem === mesHoje && diaHoje < 11 ? true : false,
        destaque: index === (qtMeses - 2) && diaHoje < 11 ? true : false
      }
      dadosrel.push(item)
      sldant = sldatu
    })

  })

  const wTab = 668


  return (
    <Layout titulo={"Relatório de Cloreto PF"} botoes={null}>
      <SpinnerModal loading={loadingRelCloretoPF} />
      <div className="overflow-x-auto ">

        <div className="flex flex-col ">
          {empresas.map((e: any, index) => {
            let totCompras = 0
            let totUtilizacao = 0
            let sldAtual = 0
            return (
              <div className="flex flex-col mx-auto mb-4" style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
                <div className="mx-4">
                  <h2 className="mt-4">{e.nome}</h2>
                  <div className="flex bg-[#0f172a] px-3 pt-1 mb-2">
                    <h3 className="w-[60px]  text-white text-left ">Mês</h3>
                    <h3 className="w-[150px] text-white text-right ">Saldo Ant (KG)</h3>
                    <h3 className="w-[150px] text-white text-right ">Compras (KG)</h3>
                    <h3 className="w-[150px] text-white text-right ">Utilizacao (KG)</h3>
                    <h3 className="w-[150px] text-white text-right ">Saldo Atual (KG)</h3>
                  </div>
                  {dadosrel.filter((dv: any) => dv.empresaId === e.id).map((x: any) => {
                    totCompras += x.compra
                    totUtilizacao += x.consumo
                    sldAtual = x.sldatu
                    geralAtual += (x.compra - x.consumo)
                    let cls = x.naoMostra ? 'text-zinc-300' : x.destaque ? 'text-red-700 font-bold' : ''
                    // console.log(x.naoMostra, cls)
                    return (
                      <div className={`flex px-3 pt-1 border-solid border-0 border-b border-[#b0d2ff]`}>
                        <h4 className={`w-[60px]  text-left  ${cls}`}>{x.mes.toString().padStart(2, '0')}/{x.ano}</h4>
                        <h4 className={`w-[150px] text-right  ${cls}`}>{formatDecimal(x.sldant, 0)}</h4>
                        <h4 className={`w-[150px] text-right  ${cls}`}>{formatDecimal(x.compra, 0)}</h4>
                        <h4 className={`w-[150px] text-right  ${cls}`}>{formatDecimal(x.consumo, 0)}</h4>
                        <h4 className={`w-[150px] text-right  ${cls}`}>{formatDecimal(x.sldatu, 0)}</h4>
                      </div>)
                  })}
                  <div className="flex px-3 pt-1 bg-slate-300 font-bold">
                    <h4 className="w-[60px] text-left ">Total...</h4>
                    <h4 className="w-[150px] text-right ">{formatDecimal(saldoant[index], 0)}</h4>
                    <h4 className="w-[150px] text-right ">{formatDecimal(totCompras, 0)}</h4>
                    <h4 className="w-[150px] text-right ">{formatDecimal(totUtilizacao, 0)}</h4>
                    <h4 className="w-[150px] text-right ">{formatDecimal(sldAtual, 0)}</h4>
                  </div>
                </div>
              </div>
            )
          }
          )}
          <div className="flex flex-col mx-auto mb-4" style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
            <div className="flex px-3 pt-1 mt-4 mx-4 bg-slate-300 font-bold">
              <h4 className="w-[60px] text-left "></h4>
              <h4 className="w-[150px] text-right "></h4>
              {/* <h4 className="w-[150px] text-right "></h4> */}
              <h4 className="w-[300px] text-right ">SALDO EM PRODUTOS ACABADOS ...</h4>
              <h4 className="w-[150px] text-right ">{formatDecimal(estEmProdAcabados, 0)}</h4>
            </div>
            <div className="flex px-3 pt-1 mt-4 mx-4 bg-slate-400 font-bold">
              <h4 className="w-[60px] text-left "></h4>
              <h4 className="w-[150px] text-right "></h4>
              <h4 className="w-[150px] text-right "></h4>
              <h4 className="w-[150px] text-right ">SALDO CONJUNTO ...</h4>
              <h4 className="w-[150px] text-right ">{formatDecimal(geralAtual, 0)}</h4>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

