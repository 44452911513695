import { useEffect, useRef, useState } from "react"

import { Button, Col, Drawer, Form, Input, InputRef, message, Modal, Row, Select, Space, Typography } from "antd";
import DatePicker from "react-datepicker";

import Layout from "../../../components/template/Layout"
import { printDiv, copyToClipboard, downloadToCsv } from "../../../utils/functions";
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined, FilterOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { useAuth } from "../../../contexts/auth";
import useWindowSize from "../../../hooks/useWindowSize";
import { columnsProduto } from "./columnsProduto";
import { useGeral } from "../../../contexts/geral";
import SpinnerNew from "../../../components/template/SpinnerNew";
import Tabela from "../../../components/template/Tabela";
import { customMessage } from "../../../components/template/CustomMessage";
import { apierp } from "../../../services/api";
import { dateFormat, datePtBrToMySql } from "../../../utils/dateFunctions";
import { MovimentosEstoque } from "./MovimentoEstoque";
import isPermitted from "../../../hooks/isPermitted";
import { FilterConfirmProps } from "antd/lib/table/interface";

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { TextArea } = Input;


export default function Estoque() {

  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  // Controla Spinner
  const [loadingProduto, setLoadingProduto] = useState(false)
  const [produtos, setProdutos] = useState([])
  const [tiposMovimento, setTiposMovimento] = useState([])
  const [depositos, setDepositos] = useState<any[]>([])
  const [motivos, setMotivos] = useState([])
  const [produto, setProduto] = useState(null as any)
  const [rowProdutoSelected, setRowProdutoSelected] = useState(-1)
  const [lastProdutoSelected, setLastProdutoSelected] = useState(-1)

  // const [drawerMovimentosEstoqueVisible, setDrawerMovimentosEstoqueVisible] = useState(false)

  const [modalFilterVisible, setModalFilterVisible] = useState(false)

  const [filterDate, setFilterDate] = useState(new Date(dateFormat(new Date(), 'yyyy-MM-dd 12:00:00')));
  const [toggleState, setToggleState] = useState(false)
  const [filterDeposito, setFilterDeposito] = useState(1)

  //ESTADOS MovimentoEstoque
  // const [isInicial, setIsInicial] = useState(false)
  const [formMovimentoEstoque] = Form.useForm();
  const [movimentosEstoque, setMovimentosEstoque] = useState(null as any)
  const [loadingMovimentoEstoque, setLoadingMovimentoEstoque] = useState(false)
  const [drawerMovimentosEstoqueVisible, setDrawerMovimentosEstoqueVisible] = useState(false)
  const [drawerFormMovimentoEstoqueVisible, setDrawerFormMovimentoEstoqueVisible] = useState(false)
  const [rowMovimentoEstoqueSelected, setRowMovimentoEstoqueSelected] = useState(-1)
  const [lastMovimentoEstoqueSelected, setLastMovimentoEstoqueSelected] = useState(-1)
  const [isInclusaoMovimentoEstoque, setIsInclusaoMovimentoEstoque] = useState(false)
  const [movimentoEstoqueBlocked, setMovimentoEstoqueBlocked] = useState(false)

  const isPermittedVisualizarPrecosProdutos = isPermitted('visualizar_precos_produtos')
  const isPermittedVisualizarNomesProdutos = isPermitted('visualizar_nomes_produtos')
  const isPermittedManterEstoques = isPermitted('manter_estoques')

    // search filter produto
    interface DataType {
      descricao: string;
      nomeComponente: string;
    }
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedKeys, setSelectedKeys] = useState('');
    const searchInput = useRef<InputRef>(null);
    type DataIndex = keyof DataType;
    const handleSearch = (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void,
      dataIndex: DataIndex,
    ) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: () => void) => {
      clearFilters();
      setSearchText('');
    };
    //end
  
    const colunasProduto = columnsProduto({
      setSearchText, setSearchedColumn, setSelectedKeys, selectedKeys, searchInput, searchedColumn, searchText
    })
    
  useEffect(() => {
    setLoadingProduto(true)
    setTimeout(async function () {
      try {
        await loadTiposMovimento()
        await loadDepositos()
        await loadProdutos()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      setLoadingProduto(false)
    }, 500);
  }, [toggleState])

  async function loadProdutos() {
    const dados = {
      data: dateFormat(filterDate, "yyyy-MM-dd") // '2022-12-31'
      // data: '2022-12-31'
    }
    const response = await apierp.post('estoquesbt', dados)
    const data = response.data as any;
    setProdutos(data);
  }
  async function loadTiposMovimento() {
    const response = await apierp.get('tiposmovimento')
    const data = response.data as any;
    setTiposMovimento(data);
  }
  async function loadDepositos() {
    const response = await apierp.get('depositos')
    const data = response.data as any;
    setDepositos(data);
  }

  async function loadMovimentoEstoque(produtoId: any) {
    const anomes = dateFormat(filterDate, "yyyy-MM")
    const response = await apierp.get(`movimentosestoque/${produtoId}/${filterDeposito}/${anomes}`)
    const data = response.data as any;
    setProduto(data);
  }

  async function handleRowProduto(record: any, index: any) {
    // console.log('handleRowProduto',record)
    setLastProdutoSelected(index)
    setLastMovimentoEstoqueSelected(-1)
    setRowProdutoSelected(index)
    await loadMovimentoEstoque(record.id)
    showDrawerMovimentosEstoque()
    // fillMovimentosEstoque(produto)
  }

  // POR ENQUANTO NÃO HAVERÁ POSSIBILIDADE DE ALTERAR MOVIMENTO ESTOQUE
  //
  async function handleRowMovimentoEstoque(record: any, index: any) {
    // console.log('produto',produto)
    // console.log('handleRowMovimentoEstoque', record)
    if (!isPermittedManterEstoques) {return}
    setMovimentosEstoque(record)
    setLastMovimentoEstoqueSelected(index)
    setRowMovimentoEstoqueSelected(index)
      // setIsInclusaoMovimentoEstoque(false) 
      // CONSIDERANDO COMO INCLUSAO APENAS PARA APROVEITAR A DATA
      setIsInclusaoMovimentoEstoque(true)
      // setIsInicial(false)
      showDrawerFormMovimentoEstoque()
      // console.log('record', record)
      const movimentoestoque: any = {
        //passando codigo e descrição pro form
        codigo: produto.codigo,
        descricao: produto.descricao,
        unidade: produto.unidade,
        id: '', // FORCANDO INCLUSÃO
        data: new Date(record.data + ' 12:00:00'),
        quantidade: +record.saldoAcumulado < 0 ? -record.saldoAcumulado : '',
        vlrUnitario: record.cmcUnitario,
        depositoId: depositos.length > 1 ? '' : depositos[0].id,
        produtoId: produto.id,
        tipomovimentoId: '',
        motivoId: '',
        // quantidade: 100,
        // vlrUnitario: 0.19,
        //// depositoId: 1,
        //// produtoId: 129,
        // tipomovimentoId: record.tipomovimentoId,
        // motivoId: record.movimentoId,
        operacao: "Movimento manual de estoque",
        observacao: ""
      }
      formMovimentoEstoque.setFieldsValue(movimentoestoque)
  }

  const handleTipoMovimentoChange = async (tipomovimentoId: any) => {
    const ix = tiposMovimento.findIndex((x: any) => x.id === tipomovimentoId)
    const tipomov: any = tiposMovimento[ix]
    // console.log(tipomov.motivos)
    formMovimentoEstoque.setFieldsValue({ motivoId: '' })
    setMotivos(tipomov.motivos)

    // await loadProdutoFt(produtoId)
    // const produto: any = produtos.find((x: any) => x.id === produtoId)
    // formOrdemProducao.setFieldsValue({
    //   id: '',
    //   depositoId: produto.depositoId,
    // });
  };

  const novoMovimentoEstoque = () => {
    // console.log('novoMovimentoEstoque')
    setButtonsDisabled(false)
    setIsInclusaoMovimentoEstoque(true)
    // setIsInicial(false)
    showDrawerFormMovimentoEstoque()
    // console.log(tiposMovimento)
    // console.log(depositos)
    setMotivos([])
    const movimentoestoque: any = {
      //passando codigo e descrição pro form
      codigo: produto.codigo,
      descricao: produto.descricao,
      unidade: produto.unidade,
      id: '',
      data: new Date(),
      quantidade: '',
      vlrUnitario: '',
      depositoId: depositos.length > 1 ? '' : depositos[0].id,
      produtoId: produto.id,
      tipomovimentoId: '',
      motivoId: '',
      operacao: "Movimento manual de estoque",
      observacao: ""
    }
    formMovimentoEstoque.setFieldsValue(movimentoestoque)
    // fillFormMovimentoEstoque(movimentoestoque)
  }

  // const movimentoEstoqueInicial = () => {
  //   console.log('movimentoEstoqueInicial')
  //   setButtonsDisabled(false)
  //   setIsInclusaoMovimentoEstoque(true)
  //   setIsInicial(true)
  //   showDrawerFormMovimentoEstoque()
  //   // console.log(tiposMovimento)
  //   // console.log(depositos)
  //   setMotivos([])
  //   const movimentoestoque: any = {
  //     //passando codigo e descrição pro form
  //     codigo: produto.codigo,
  //     descricao: produto.descricao,
  //      unidade: produto.unidade,
  //     id: '',
  //     data: new Date(),
  //     quantidade: '',
  //     vlrUnitario: '',
  //     depositoId: depositos.length > 1 ? '' : depositos[0].id,
  //     produtoId: produto.id,
  //     tipomovimentoId: '2', //Criar movimento de entrada
  //     motivoId: '1', //Ajuste por inventario (inicial)
  //     operacao: "Movimento manual - Estoque inicial",
  //     observacao: ""
  //   }
  //   formMovimentoEstoque.setFieldsValue(movimentoestoque)
  //   // fillFormMovimentoEstoque(movimentoestoque)
  // }

  const showDrawerMovimentosEstoque = () => {
    setDrawerMovimentosEstoqueVisible(true)
  };
  const hideDrawerMovimentosEstoque = () => {
    setDrawerMovimentosEstoqueVisible(false)
  };

  // Drawers Form MovimentoEstoque
  const showDrawerFormMovimentoEstoque = () => {
    // setButtonsDisabled(false)
    setDrawerFormMovimentoEstoqueVisible(true)
  };
  const hideDrawerFormMovimentoEstoque = async () => {
    // setButtonsDisabled(false)
    // await loadFichasTecnicas(produto.id)
    setRowMovimentoEstoqueSelected(-1)
    setDrawerFormMovimentoEstoqueVisible(false)
  };

  const showModalFilter = async () => {
    setModalFilterVisible(true)
  };
  const hideModalFilter = async () => {
    setModalFilterVisible(false)
  };

  const handleChangeDate = (newDate: any) => {
    setFilterDate(newDate)
    setToggleState(!toggleState)
    hideModalFilter()
  }

  //TODO NÃO PERMITIR QUANTIDADE NEM VLRUNITARIO NEGATIVO
  async function handleSubmitMovimentoEstoque(dados: any) {
    // setButtonsDisabled(true)
    // dados.data = dateFormat(dados.data, 'dd/MM/yyyy')
    dados.data = dateFormat(dados.data, 'yyyy-MM-dd')
    dados.quantidade=dados.quantidade.toString().replace(",",".").replace("-","")
    dados.vlrUnitario=dados.vlrUnitario.toString().replace(",",".").replace("-","")
    // console.log('dados', dados)
    if (dados.id === '') {
      delete dados.id
      // CREATE
      try {
        const result = await apierp.post('movimentoestoque', dados);
        setToggleState(!toggleState)
        message.success('Movimento Estoque incluido com sucesso!');
        hideDrawerFormMovimentoEstoque()
        await loadMovimentoEstoque(dados.produtoId);
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    } else {
      //UPDATE
      // try {
      //   const result = await apierp.put(`fichatecnicaitens/${dados.id}`, dados);
      //   setToggleState(!toggleState)
      //   message.success('Item da Ficha Técnica alterada com sucesso!');
      //   hideDrawerFormMovimentoEstoque()
      // } catch (err) {
      //   if (customMessage(err) === 403) Logout()
      // }
    }

  };

  // TABELA COM FOOTER
  const dadosFlat = []
  let totalInventario = 0.0
  produtos.forEach((it: any) => {
    totalInventario += it.cmcTotal
    const item = {
      ...it,
      footer: false,
      addClass: it.situacao === "Ativo" ? "" : "text-gray-400" 
    }
    dadosFlat.push(item)
  })
  const total = {
    id: '#',
    categoria: 'Total',
    codigo: '',
    descricao: '',
    nomeComponente: '',
    unidade: '',
    saldoAcumulado: NaN,
    cmcUnitario: '',
    cmcTotal: totalInventario,
    situacao: '',
    footer: true,
  }
  dadosFlat.push(total)

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-produto', 'produto')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('produto')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('produto', "Estoques.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        <Button onClick={() => showModalFilter()} shape="round" size="small" icon={<FilterOutlined />} title="Filtrar data"></Button>
        {/* <div>{innerW}</div> */}
      </Space>
    )
  }

  const colunas = colunasProduto.slice() //faz uma copia por valor
  if (!isPermittedVisualizarPrecosProdutos) {
    colunas.splice(6, 2)
  }
  if (!isPermittedVisualizarNomesProdutos) {
    colunas[2].dataIndex='nomeComponente'
  } else {
    colunas[2].dataIndex='descricao'
  }

  const wTab = isPermittedVisualizarPrecosProdutos ? 1165+80+10+160+40 : 1165+80+10+160+40 - 190 // liquido = x-8
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb
  const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
  const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144

  return (
    <Layout titulo={menu.label + ` em ${dateFormat(filterDate, "dd/MM/yyyy")}`} botoes={botoes()}>
      <SpinnerNew loading={loadingProduto} />
      <Tabela className="mx-5" id='produto' titulo={menu.label + ` em ${dateFormat(filterDate, "dd/MM/yyyy")}`} wTab={wTab} htb={htb} hsc={hsc} dataSource={dadosFlat} columns={colunas} handleRow={handleRowProduto} rowSelected={rowProdutoSelected} lastSelected={lastProdutoSelected} loading={loadingProduto} />

      {/* MODAL FILTER */}
      <Modal title="Data do Estoque:" open={modalFilterVisible} onOk={hideModalFilter} onCancel={() => setModalFilterVisible(false)} footer={[]} centered width={250}>
        {/* <h5>Data:</h5> */}
        <div className="flex justify-around">
          {/* <DatePicker className="w-[170px]" placeholder="Selecione uma data"  onChange={onChangeDate} format={'DD/MM/YYYY'}/> */}
          <DatePicker className="ant-input" selected={filterDate} locale="pt-BR" dateFormat="dd/MM/yyyy" onChange={(date: any) => handleChangeDate(date)} todayButton="Hoje" />
        </div>
      </Modal>


      {/* DRAWER TABELA MOVIMENTOS ESTOQUE */}
      <Drawer
        title="Movimento de Estoque"
        width={wDrawer}
        closable={true}
        onClose={hideDrawerMovimentosEstoque}
        open={drawerMovimentosEstoqueVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body justify-start">
          <MovimentosEstoque produto={produto} novaMovimentoEstoque={novoMovimentoEstoque}
            handleRowMovimentoEstoque={handleRowMovimentoEstoque}
            // handleRowMovimentoEstoque={() => { }}
            rowMovimentoEstoqueSelected={rowMovimentoEstoqueSelected}
            lastMovimentoEstoqueSelected={lastMovimentoEstoqueSelected}
            loadingMovimentoEstoque={loadingMovimentoEstoque}
            filterDate={filterDate}
          />
        </div>


        <Space className="drawer-footer flex w-full justify-start">
          <Space>
            <Button onClick={() => { hideDrawerMovimentosEstoque() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerMovimentosEstoque}>Cancel</Button>
            {/* <Button onClick={novoMovimentoEstoque} type="primary" shape="round" size="small" icon={<PlusOutlined />} hidden={!isPermittedManterEstoques} title="Novo"></Button> */}
        </Space>
        </Space>
      </Drawer>

      {/* DRAWER FORM MOVIMENTO ESTOQUE */}
      <Drawer
        title={(isInclusaoMovimentoEstoque ? "Novo" : "Alterar") + " MovimentoEstoque"}
        // width={wDrawerx}
        width={360}
        closable={true}
        // maskClosable={false}
        onClose={hideDrawerFormMovimentoEstoque}
        open={drawerFormMovimentoEstoqueVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={formMovimentoEstoque} onFinish={handleSubmitMovimentoEstoque} >
            {/* 
            //TODO o movimento estoque não tem estes dados, obter do produto na rowselected: */}
            <Title level={5}>{formMovimentoEstoque.getFieldValue('codigo')} - {formMovimentoEstoque.getFieldValue('descricao')} ({formMovimentoEstoque.getFieldValue('unidade')})</Title>
            {/* <Title level={5}>{formMovimentoEstoque.getFieldValue('descricao')}</Title>  */}
            <Form.Item name="id" hidden noStyle></Form.Item>
            <Form.Item name="produtoId">Produto Id: {formMovimentoEstoque.getFieldValue('produtoId')}</Form.Item>
            <Form.Item name="operacao" hidden noStyle></Form.Item>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="data"
                  label="Data"
                  rules={[{ required: true, message: 'Data Obrigatória' }]}
                >
                  <DatePicker className="ant-input" selected={formMovimentoEstoque.getFieldValue('data')} locale="pt-BR" dateFormat="dd/MM/yyyy" onChange={(date: any) => handleChangeDate(date)} todayButton="Hoje" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="tipomovimentoId"
                  label="Tipo Movimento"
                rules={[{ required: true, message: 'Selecione Um Tipo de Movimento' }]}
                >
                  <Select placeholder="Tipo Movimento" onChange={handleTipoMovimentoChange}>
                    <Option key="xx" value="">Selecione um Tipo</Option>
                    {tiposMovimento.filter((x: any) => x.id === 2 || x.id === 3)
                      .map((tipomovimento: any, index: any) => {
                        return <Option key={tipomovimento.id} value={tipomovimento.id}> {tipomovimento.nome}</Option>
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="motivoId"
                  label="Motivo"
                rules={[{ required: true, message: 'Selecione Um Motivo' }]}
                >
                  <Select placeholder="Motivo">
                    <Option key="yy" value="">Selecione um Motivo</Option>
                    {motivos.map((motivo: any, index: any) => {
                      return <Option value={motivo.id}> {motivo.nome}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="depositoId"
                  label="Deposito"
                rules={[{ required: true, message: 'Selecione Um Depósito' }]}
                >
                  <Select placeholder="Depósito">
                    {depositos.length > 1 ? <Option key="yy" value="">Selecione um Deposito</Option> : null}
                    {depositos.map((deposito: any, index: any) => {
                      return <Option value={deposito.id}> {deposito.nome}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="quantidade"
                  label="Quantidade"
                rules={[{ required: true, message: 'Quantidade Obrigatória' }]}
                >
                  <Input placeholder="Quantidade" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="vlrUnitario"
                  label="Valor Unitário"
                rules={[{ required: true, message: 'Valor Unitário Obrigatório' }]}
                >
                  <Input placeholder="Valor Unitário" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="observacao"
                  label="Observação"
                  rules={[{ required: true, message: 'Justificativa Obrigatória' }]}
                  >
                  <TextArea rows={2} placeholder="Justificativa Obrigatória" disabled={!isInclusaoMovimentoEstoque} />
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </div>

        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={() => { hideDrawerFormMovimentoEstoque(); hideDrawerMovimentosEstoque() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFormMovimentoEstoque}>Cancel</Button>
            {/* <Popconfirm disabled={MovimentoEstoqueBlocked}
              title="Deseja realmente excluir este componente?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => { }} type="primary" danger disabled >Delete</Button>
            </Popconfirm> */}

            <Button onClick={formMovimentoEstoque.submit} disabled={movimentoEstoqueBlocked || buttonsDisabled} type="primary">Submit</Button>
          </Space>
        </Space>
      </Drawer>
    </Layout>
  )
}

