import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const { Login: login } = useAuth();

  let navigate = useNavigate();

  useEffect(() => {
    setMsgError('')
    // console.group('URL Info');
    //   console.log('Protocol', window.location.protocol);
    //   console.log('Host', window.origin);
    //   console.log('Path', window.location.pathname);
    //   console.groupCollapsed('Meta Info');
    //     console.log('Date Fetched', new Date().getTime());
    //     console.log('OS', navigator['platform']);
    //     console.log('Browser', navigator['appCodeName']);
    //     console.log('Language', navigator['language']);
    //   console.groupEnd();
    // console.groupEnd();
  }, [])

  const [formLogin] = Form.useForm();
  const [buttonsEnabled, setButtonsEnabled] = React.useState(true);
  const [msgError, setMsgError] = useState('')

  function enableButtons(wait: number) {
    setTimeout(function () {
      setButtonsEnabled(true)
      setMsgError('')
    }, wait);
  }
  function disableButtons() {
    setButtonsEnabled(false)
  }

  async function handleSubmitLogin(dados: any) {
    setButtonsEnabled(false)
    const ret: any = await login({
      email: dados.email.toLowerCase().trim(),
      password: dados.password.trim(),
    });

    if (ret.status !== 0) {
      setMsgError(ret.message)
      enableButtons(2500)
    } else {
      // console.log('logado', ret.user.mustChangePassword)
      if (ret.user.mustChangePassword) {
        navigate(`/profile`);
      }

      // formLogin.resetFields();
      // setMenuSelected(0);
      // route.push('/')
    }
  };

  return (
    <div className="min-h-screen flex flex-row justify-center items-center">
      <div >
        <div title="Login" className="bg-blue-50 shadow-lg rounded-lg" >
          <img alt="Card" width={300} height={150}
            style={{ objectFit: "cover", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
            src="/images/apphost2x1.svg"
          />

          <div className="flex flex-col">
            <h1 className="text-2xl font-bold text-center my-4">Login</h1>
          </div>
          <Form
            layout="vertical" form={formLogin} onFinish={handleSubmitLogin} >
            <div className='px-4 pb-4'>
              <Form.Item
                name="email"
                label="Email:"
                rules={[{ required: true, message: 'Email é obrigatório' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item className='xmt-4'
                name="password"
                label="Senha:"
                rules={[{ required: true, message: 'Senha é obrigatória' }]}
              >
                <Input.Password />
              </Form.Item>
              <div className={msgError ? "bg-red-700 min-h-[24px] px-4 mb-2 font-semibold text-white" : "bg-blue-50 min-h-[24px] px-4 mb-2 font-semibold text-white"}>
                {msgError}
              </div>
              <div className="flex justify-center">
                <Button onClick={formLogin.submit} disabled={!buttonsEnabled} type="primary">Submit</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )

}
