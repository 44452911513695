import { situacaoProduto, tipoProducao, tipoProduto, tipositem, origens } from "../../../data/dados"
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { formatDecimalZ } from "../../../utils/functions";
import { dateFormatBr } from "../../../utils/dateFunctions";

export function CardProduto(props: any) {
  const { produto, wCard, key, className, formato, alterarProduto, debug } = props

  // console.log('CardProduto', debug)
  // console.log(produto)
  // alert('CardProduto pausa')
  const classLabel = "min-w-[72px] text-left font-medium"
  // const icon=''
  // const icon = produto?.situacao ? produto?.situacao[0] === 'A' ? <FaEye className="text-green-500" /> : <FaEyeSlash className="text-red-500" /> : ''
  const bgcor = produto?.situacao ? produto?.situacao[0] === 'A' ? 'bg-green-100' : 'bg-red-100' : ''
  const tipo: any = tipoProduto.find(s => s.value === produto?.tipo)
  const tipoItem: any = tipositem.find(s => s.value === produto?.spedTipoItem)
  const producao: any = tipoProducao.find(s => s.value === produto?.producao)
  const origem: any = origens.find(s => s.value === String(produto?.origem))
  const situacao = situacaoProduto.find(s => s.value === produto?.situacao)?.label || produto?.situacao
  return (
    <div key={key} className={`flex flex-col gap-1 card-produto p-2 ${`w-[${wCard}px]`} ${`min-w-[${wCard}px]`}  ${`max-w-[${wCard}px]`} ${bgcor}`} >

      <div className="flex">
        <div className="flex"><div className={classLabel}>Codigo:</div><div className="font-bold pl-2 w-20">{produto?.codigo}</div></div>
        <div className="flex"><div className="ml-2 font-medium"></div>{/*<div className="pl-2">{icon}</div>*/} <div className="pl-1">{situacao}</div></div>
        <div className="flex"><div className="ml-4 font-medium">Unidade:</div><div className="font-bold pl-2 w-5">{produto?.unidade}</div></div>
        <div className="flex flex-1 justify-between">
          <div className="flex"><div className="ml-4 font-medium">Preço Venda:</div><div className="pl-2 w-14">{formatDecimalZ(produto?.precoVenda || produto?.preco, 2)}</div></div>
          {/* {Boolean(alterarProduto && (produto?.id))
            ? <div className="cursor-pointer" onClick={() => alterarProduto()}>#{produto?.id}{produto?.idbling}</div>
            : <div className="text-right">#{produto?.id}{produto?.idbling}</div>
          } */}
          <div className="text-right">#{produto?.id}{produto?.idbling}</div>
          
        </div>
      </div>

      <div className="flex"><div className={classLabel}>Produto:</div>
          <div className="font-bold pl-2">{produto?.nome}</div>
      </div>


      <div className="flex">
        {/* <div className="flex"><div className={classLabel}>Preço Venda:</div><div className="pl-2 w-14">{produto?.precoVenda}</div></div> */}
        <div className="flex"><div className={classLabel}>Peso Bruto:</div><div className="pl-2 w-16">{produto?.pesoBruto && String(produto?.pesoBruto).replace('.',',')}</div></div>
        <div className="flex"><div className="ml-2 font-medium">Peso Liq.:</div><div className="pl-2 w-16">{produto?.pesoLiquido && String(produto?.pesoLiquido).replace('.',',')}</div></div>
        <div className="flex"><div className="ml-2 font-medium">Larg.:</div><div className="pl-2 w-14">{produto?.largura && String(produto?.largura).replace('.',',')}</div></div>
        <div className="flex"><div className="ml-2 font-medium">Alt.:</div><div className="pl-2 w-14">{produto?.altura && String(produto?.altura).replace('.',',')}</div></div>
        <div className="flex"><div className="ml-2 font-medium">Prof.:</div><div className="pl-2 w-14">{produto?.profundidade && String(produto?.profundidade).replace('.',',')}</div></div>
      </div>

      <div className="flex">
        <div className="flex"><div className={classLabel}>Tipo:</div><div className="pl-2 w-14">{tipo?.label}</div></div>
        <div className="flex"><div className="ml-4 font-medium">Producao:</div><div className="pl-2 w-14">{producao?.id && producao?.label}</div></div>
        <div className="flex"><div className="ml-4 font-medium">Categoria:</div><div className="pl-2 w-full">{produto?.categoria}</div></div>
      </div>

      {/* <div className="flex"><div className={classLabel}>empresaIdRef:</div><div className="pl-2">{produto?.empresaIdRef}</div></div> */}

      <div className="flex">
        <div className="flex"><div className={classLabel}>Tipo Item:</div><div className="pl-2 w-48">{tipoItem?.value}-{tipoItem?.label}</div></div>
        <div className="flex"><div className="ml-4 font-medium">NCM:</div><div className="pl-2">{produto?.ncm}</div></div>
        <div className="flex"><div className="ml-4 font-medium">Origem:</div><div className="pl-2">{origem?.value}-{origem?.label.split(':')[0]} ...</div></div>
      </div>

      <div className="flex" hidden={formato===1}>
        <div className="flex"><div className="min-w-[100px] font-medium">Ultima Compra:</div><div className="pl-2 w-20">{produto?.dataUltimaCompra}</div></div>
        <div className="flex"><div className="ml-4 font-medium">Preco Compra:</div><div className="pl-2 w-14">{produto?.precoCompra}</div></div>
        <div className="flex"><div className="ml-4 font-medium">Estoque Minimo:</div><div className="pl-2">{produto?.estoqueMinimo}</div></div>
      </div>

      <div className="flex">
        <div className="flex"><div className="min-w-[100px] font-medium">dataAlteracao:</div><div className="pl-2">{dateFormatBr(produto?.dataAlteracao)}</div></div>
        <div className="flex" hidden={formato===1}><div className="ml-4 font-medium">Data Cmc:</div><div className="pl-2 w-20">{produto?.dataCmc}</div></div>
        <div className="flex" hidden={formato===1}><div className="ml-4 font-medium">Cmc:</div><div className="pl-2 w-14">{produto?.precoCmc}</div></div>
      </div>

    </div>
  )
}
