import { message } from 'antd';
import { useGeral } from "../contexts/geral";

// export const isBrowser = (): boolean => {
//   return typeof window !== 'undefined'
// }

// export const nextLocalStorage = (): Storage | void => {
//   if (isBrowser()) {
//     return window.localStorage
//   }
// }

export const isBrowser = () => {
  return typeof window !== 'undefined'
}

export const nextLocalStorage = () => {
  if (isBrowser()) {
    return window.localStorage
  }
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function formatLength(value, len) {
  if (!value) return ''
  const formated = value.toString().replace('.', ',').substring(0, len)
  return formated
}

export function formatDecimal(value, decimals) {
  // if (isNaN(value)) return ''
  if (!value) return ''
  const formated = value.toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  return formated;
}
export function formatDecimalZ(value, decimals) {
  if (isNaN(value)) return ''
  const formated = value.toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  return formated;
}
export function formatPercentual(value, decimals) {
  if (!value) return ''
  const formated = value.toLocaleString('pt-BR', {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  return formated;
}

export function formatarCpfCnpj(valor) {
  if (!valor) return '';
  const valorNumeros = valor.replace(/\D/g, ''); // remove qualquer caractere não numérico

  if (valorNumeros.length === 11) {
    // Formata como CPF: 000.000.000-00
    return valorNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (valorNumeros.length === 14) {
    // Formata como CNPJ: 00.000.000/0000-00
    return valorNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  return valor; // retorna o valor original se não tiver 11 ou 14 caracteres
}

export function formatarCep(valor) {
  if (!valor) return '';
  const valorNumeros = valor.replace(/\D/g, ''); // remove qualquer caractere não numérico
  return valorNumeros.replace(/(\d{5})(\d{3})/, "$1-$2");
}

function groupOneBy(array, groups, valueKey) {
  const map = new Map();
  groups = [].concat(groups);
  return array.reduce((r, o) => {
    groups.reduce((m, k, i, { length }) => {
      let child;
      if (m.has(o[k])) return m.get(o[k]);
      if (i + 1 === length) {
        child = Object.assign(...groups.map(y => ({ [y]: o[y] })), {
          [valueKey]: 0,
        });
        r.push(child);
      } else {
        child = new Map();
      }
      m.set(o[k], child);
      return child;
    }, map)[valueKey] += +o[valueKey];
    return r;
  }, []);
}

export function groupBy(array, groups, values) {
  let res = [];
  values = [].concat(values);
  if (values.length === 1) {
    return groupOneBy(array, groups, values);
  }
  values.forEach((item, i) => {
    res[i] = groupOneBy(array, groups, item);
  });
  // JUNTANDO
  let newres = res[0];
  for (let i = 1; i < res.length; i++) {
    res[i].forEach((item, j) => {
      const keys = Object.keys(res[i][j]);
      const key = keys[keys.length - 1];
      newres[j][key] = res[i][j][key];
    });
  }
  return newres;
}

export function printDiv(id, tbl = 'xx') {

  if (tbl === 'xx') {
    return
  }

  const style = `<style>
      body {
        font-family: "Exo 2", Nunito, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
        font-size: 1rem;
      }
      .text-left {
        text-align: left !important;
      }
      .text-center {
        text-align: center !important;
      }
      .text-right {
        text-align: right !important;
      }
      td, th {
        padding: 0 4px;
      }
    </style>`

  const tblScroll = document.getElementById(tbl).firstChild.children[1].cloneNode(true)

  const divToPrint = document.getElementById(id);

  //REMOVER SCROLL PARA IMPRIMIR TUDO
  document.getElementById(tbl).firstChild.children[1].removeAttribute("style")

  // const newWin = window.open("", null, "popup");
  const newWin = window.open("");
  newWin?.document.write(style);
  newWin?.document.write(divToPrint.outerHTML);
  const buttons = newWin?.document.getElementsByTagName("button")
  for (let i = buttons.length; i > 0; i--) {
    buttons[i - 1].remove();
  }
  const sorters = newWin?.document.getElementsByClassName("ant-table-column-sorter-full")
  for (let i = sorters.length; i > 0; i--) {
    sorters[i - 1].remove();
  }
  const filters = newWin?.document.getElementsByClassName("ant-dropdown-trigger")
  for (let i = filters.length; i > 0; i--) {
    filters[i - 1].remove();
  }

  const tbls = newWin?.document.getElementsByTagName("table")
  const colg0 = tbls[0].getElementsByTagName("colgroup")
  // const in0 = colg0[0].innerHTML
  // const ou0 = colg0[0].outerHTML
  // console.log('colg0', in0, ou0)

  const colg1 = tbls[1].getElementsByTagName("colgroup")
  const in1 = colg1[0].innerHTML
  const ou1 = colg1[0].outerHTML
  // console.log('colg1', in1, ou1)
  
  colg0[0].innerHTML = in1
  colg0[0].outerHTML = ou1
  // console.log('colg0', colg0[0].innerHTML, colg0[0].outerHTML)
  
  // VOLTA AO SCROLL NA TELA
  document.getElementById(tbl).firstChild.children[1].setAttribute("style", tblScroll.getAttribute("style"))

  newWin?.print();
  newWin?.close();
}

export async function copyToClipboard(id) {
  selectElementContents(document.getElementById(id))
  message.info('Tabela ' + id + ' copiada');
}

export async function downloadToCsv(id, name) {
  // console.log(id)
  const el = document.getElementById(id)
  exportData(el, name)
}

export function selectElementContents(el) {
  var body = document.body, range, sel;
  if (document.createRange && window.getSelection) {
    // console.log('xxx-if')
    range = document.createRange();
    sel = window.getSelection();
    sel.removeAllRanges();
    try {
      range.selectNodeContents(el);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(el);
      sel.addRange(range);
    }
    document.execCommand("copy");
    // console.log('document', document)
    sel.removeAllRanges();

  } else if (body.createTextRange) {
    // console.log('xxx-else')
    range = body.createTextRange();
    range.moveToElementText(el);
    range.select();
    range.execCommand("Copy");
    sel.removeAllRanges();
  }
}

export function exportData(el, name) {
  //O componente Table  do ant gera duas tables
  const tableHeader = el.firstChild.children[0].firstChild
  // console.log('tableHeader', tableHeader)
  const tableBody = el.firstChild.children[1].firstChild
  // console.log('tableBody', tableBody)
  // el.firstChild.children[1].removeAttribute("style")
  const rows = [];

  //OBTENDO HEADER
  const rowHeader = tableHeader.rows[0]
  // console.log('rowHeader', rowHeader)
  const cols = []
  for (let c = 0, col; col = rowHeader.cells[c].innerText; c++) {
    cols.push(col)
  }
  rows.push(cols)
  const nc = cols.length

  //OBTENDO AS DEMAIS LINHAS
  //A row 0 contem células vazias para dar espaço na lista (ANT DESIGN)
  // console.log('\ntableBody', tableBody)
  for (let i = 1, row; row = tableBody.rows[i]; i++) {
    // console.log('row', i, row)
    const lcols = []
    for (let c = 0, col; c < nc; c++) {
      col = `\"${row.cells[c].innerText.trim()}\"`
      lcols.push(col)
    }
    rows.push(lcols)
  }

  //
  let csvContent = "";
  /* add the column delimiter as comma(,) and each row splitted by new line character (\n) */
  rows.forEach(function (rowArray) {
    const row = rowArray.join(";");
    csvContent += row + "\r\n";
  });
  /* create a hidden <a> DOM node and set its download attribute */
  var encodedUri = encodeURIComponent(csvContent);

  var link = document.createElement("a");
  // link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodedUri);
  link.setAttribute("href", "data:text/csv;charset=utf-8,\uFEFF" + encodedUri);
  link.setAttribute("download", name);
  document.body.appendChild(link);
  /* download the data file named name */
  link.click();
}

export function ellipsis(txt, len) {
  if (txt.length > len + 2)
    return txt.substring(0, len - 2) + '...'
  else
    return txt
}

function allDigitsAreEqual(str) {
  const firstDigit = str[0];
  for (let i = 1; i < str.length; i++) {
    if (str[i] !== firstDigit) {
      return false;
    }
  }
  return true;
}

export function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '' || cpf.length !== 11 || allDigitsAreEqual(cpf)) return false;
  let add = 0;
  for (let i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11)
    rev = 0;
  if (rev !== parseInt(cpf.charAt(9)))
    return false;
  add = 0;
  for (let i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11)
    rev = 0;
  if (rev !== parseInt(cpf.charAt(10)))
    return false;
  return true;
}

export function validateCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  if (cnpj === '' || cnpj.length !== 14 || allDigitsAreEqual(cnpj)) return false;
  // Valida DVs
  let size = cnpj.length - 2
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
  if (result !== parseInt(digits.charAt(0)))
    return false;
  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - sum % 11;
  if (result !== parseInt(digits.charAt(1)))
    return false;
  return true;
}

export function validateCPFOrCNPJ(text) {
  return validateCPF(text) || validateCNPJ(text);
}

export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function validatePhone(phone) {
  const re = /\(\d{2}\)\s\d{4,5}-\d{4}/;
  return re.test(phone);
}

export function validateCEP(cep) {
  const re = /\d{5}-\d{3}/;
  return re.test(cep);
}

export function validateDate(date) {
  const re = /^\d{4}-\d{2}-\d{2}$/;
  return re.test(date);
}

export function validateTime(time) {
  const re = /^\d{2}:\d{2}$/;
  return re.test(time);
}

export function validateDateTime(datetime) {
  const re = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
  return re.test(datetime);
}

export function validateNumber(number) {
  const re = /^\d+$/;
  return re.test(number);
}

export function validateDecimal(decimal) {
  const re = /^\d+(\.\d+)?$/;
  return re.test(decimal);
}

export function validatePercentual(percentual) {
  const re = /^\d+(\.\d+)?$/;
  return re.test(percentual);
}

export function validatePassword(password) {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return re.test(password);
}

export function validateName(name) {
  const re = /^[a-zA-Z\s]+$/;
  return re.test(name);
}

export function validateUsername(username) {
  const re = /^[a-zA-Z0-9]+$/;
  return re.test(username);
}

export function validateCaptcha(captcha) {
  const re = /^[a-zA-Z0-9]+$/;
  return re.test(captcha);
}

export function validateUf(uf) {
  const listaUf = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']
  return listaUf.includes(uf);
}

export function getEmpresaByCnpj(cnpj) {
  const url1 = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`
  // const url2 = `https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpj}`
  const url3 = `https://cors-anywhere.herokuapp.com/https://www.receitaws.com.br/v1/cnpj/${cnpj}`
  const dados = fetch(url3)
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch((error) => {
      console.error('Error:', error);
      return null
    });

  const empresa = {
    abertura: dados?.abertura,
    situacao: dados?.situacao,
    data_situacao: dados?.data_situacao,
    motivo_situacao: dados?.motivo_situacao,
    status: dados?.status,
    nome: dados?.nome,
    fantasia: dados?.fantasia,
    cep: dados?.cep,
    logradouro: dados?.logradouro,
    numero: dados?.numero,
    complemento: dados?.complemento,
    bairro: dados?.bairro,
    municipio: dados?.municipio,
    uf: dados?.uf,
    telefone: dados?.telefone,
    email: dados?.email,
  }

  return empresa
}

// export function getExpiresIn(expires) {
//   const now=(new Date()).getTime()/1000
//   const left=+expires-now
//   const hh=Math.floor(left/3600)
//   const rest=left-(3600*(hh))
//   const mm=Math.floor(rest/60)
//   const ss= Math.floor(rest-(60*(mm)))
//   const expiresIn = `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}:${ss.toString().padStart(2, '0')}`
//   return expiresIn
// }

// export function checkToken(exp) {
//   const now = (new Date()).getTime() / 1000
//   if (+exp - now < 0) {
//     // customMessage({ message: 'Sessão Expirada!, Faça login Novamente' });
//     logout()
//   }
// }
