import React from 'react';
import { BrowserRouter as Router, Routes,  Route } from 'react-router-dom';

import NotFound from '../pages/_NotFound';

// import SideMenu from '../components/SideMenu';
import Home from '../pages/_Home';
import HomeTenant from '../pages/_HomeTenant';
import Menu from '../pages/_Menu';
// import CustoProducao from '../pages/CustoProducao';
// import Componente from '../pages/Componente';

// DASHBOARD
import DashboardClientes from '../pages/Dashboard/Clientes';
import DashboardVendas from '../pages/Dashboard/Vendas';

import User from '../pages/_User';
import Role from '../pages/_Role';
import Tenant from '../pages/_Tenant';
import Submenu from '../pages/_Submenu';
import Permission from '../pages/_Permission';
import Colors from '../pages/_Colors';
import Profile from '../pages/_Profile';
import AImplementar from '../pages/_AImplementar';

// BLING V2
import ImportaBlingContasPagar from '../pages/Bling/ContasPagar/Importar';
import ImportaBlingPedidosCompraV2 from '../pages/Bling/PedidosCompraV2/Importar';
import ImportaBlingPedidosVendaV2 from '../pages/Bling/PedidosVendaV2/Importar';
import ImportaBlingProdutos from '../pages/Bling/Produtos/Importar';

//BlingV3
import TokensApiBlingV3 from '../pages/BlingV3/_TokensApiBlingV3';
import ImportaBlingV3Produtos from '../pages/BlingV3/Produtos/Importar';

// RELATORIOS
import RelVendas from '../pages/Relatorios/Vendas';
import RelVendasVendedor from '../pages/Relatorios/VendasVendedor';
import RelCloretoPF from '../pages/Relatorios/CloretoPF';
import PrecoMedioGeral from '../pages/Relatorios/PrecoMedioGeral';

// FINANCEIRO
import Dre from '../pages/Financeiro/Dre';

// SUPRIMENTOS
import ConsumoMensalMedio from '../pages/Suprimentos/ConsumoMensalMedio';
import EntradaMercadoriasByMonth from '../pages/Suprimentos/EntradaMercadoriasByMonth';
import SaidaMercadoriasByDate from '../pages/Suprimentos/SaidaMercadoriasByDate';
import EstoqueDividido from '../pages/Suprimentos/EstoqueDividido';
import EstoqueDivididoSped from '../pages/Suprimentos/EstoqueDividido/Sped';
import EstoqueDivididoSpedGrouped from '../pages/Suprimentos/EstoqueDividido/SpedGrouped';
import Estoque from '../pages/Suprimentos/Estoque';
import OrdemProducao from '../pages/Suprimentos/OrdemProducaoV2';

// CADASTROS
import Produto from '../pages/Produto';
import ClienteVinculo from '../pages/Cadastros/ClienteVinculo';
import ClienteRanking from '../pages/Cadastros/ClienteRanking';
import ClienteMapa from '../pages/Cadastros/ClienteMapa';
import StatusCrm from '../pages/Cadastros/StatusCrm';
import ProdutoMapa from '../pages/Cadastros/ProdutoMapa';

const OtherRoutes: React.FC = () => {
 return (
  //  <SideMenu>
   <Router >
     <Routes>
       <Route path="/" element={<Home />} /> 
       <Route path="/zapp/:tid" element={<HomeTenant />} /> 
       <Route path="/zapp/:tid/:menuid" element={<Submenu />} /> 
       <Route path="/zapp/:tid/menus" element={<Menu />} /> 
       <Route path="/zapp/:tid/tenants" element={<Tenant />} /> 
       <Route path="/zapp/:tid/permissions" element={<Permission />} /> 
       <Route path="/zapp/:tid/colors" element={<Colors />} /> 

       //BLING V3 // TODO - MUDAR PARA bling/v3 e mudar no MENU
       <Route path="/zapp/:tid/tokensapiblingv3" element={<TokensApiBlingV3 />} /> 
       <Route path="/zapp/:tid/bling/produtos" element={<ImportaBlingV3Produtos />} />


       {/* <Route path="/zapp/:tid/linksuteis" element={<LinksUteis />} />  */}

       <Route path="/zapp/:tid/users" element={<User />} />
       <Route path="/zapp/:tid/roles" element={<Role />} />
       <Route path="/zapp/:tid/produtos" element={<Produto />} />
       <Route path="/zapp/:tid/produtosmapa" element={<ProdutoMapa />} />
       <Route path="/zapp/:tid/clientesvinculo" element={<ClienteVinculo />} />
       <Route path="/zapp/:tid/clientesmapa" element={<ClienteMapa />} />
       <Route path="/zapp/:tid/clientesranking" element={<ClienteRanking />} />
       <Route path="/zapp/:tid/statuscrm" element={<StatusCrm />} />
       
       <Route path="/zapp/:tid/estoques" element={<Estoque />} />
       <Route path="/zapp/:tid/estoquedividido" element={<EstoqueDividido />} />
       <Route path="/zapp/:tid/estoquedivididosped" element={<EstoqueDivididoSped />} />
       <Route path="/zapp/:tid/estoquedivididospedgrouped" element={<EstoqueDivididoSpedGrouped />} />

       <Route path="/zapp/:tid/entradamercadoriasbymonth" element={<EntradaMercadoriasByMonth />} />
       <Route path="/zapp/:tid/saidamercadoriasByDate" element={<SaidaMercadoriasByDate />} />
       <Route path="/zapp/:tid/consumomensalmedio" element={<ConsumoMensalMedio />} />
       <Route path="/zapp/:tid/ordensproducao" element={<OrdemProducao />} />
       {/* <Route path="/planocontas" element={PlanoContas} /> */}

       // BLING V2
       <Route path="/zapp/:tid/blingcontaspagar" element={<ImportaBlingContasPagar />} />
       <Route path="/zapp/:tid/blingpedidoscomprav2" element={<ImportaBlingPedidosCompraV2 />} />
       <Route path="/zapp/:tid/blingpedidosvendav2" element={<ImportaBlingPedidosVendaV2 />} />
       <Route path="/zapp/:tid/blingprodutos" element={<ImportaBlingProdutos />} />

       <Route path="/profile" element={<Profile />} />

       <Route path="/zapp/:tid/relvendas" element={<RelVendas />} />
       <Route path="/zapp/:tid/relvendasvendedor" element={<RelVendasVendedor />} />
       <Route path="/zapp/:tid/relcloretopf" element={<RelCloretoPF />} />
       <Route path="/zapp/:tid/precomedio" element={<PrecoMedioGeral />} />
       <Route path="/zapp/:tid/dre" element={<Dre />} />

       <Route path="/zapp/:tid/dashclientes" element={<DashboardClientes/>} />
       <Route path="/zapp/:tid/dashvendas" element={<DashboardVendas />} />

       <Route path="*" element={<NotFound />} />
     </Routes>
   </Router>
  //  </SideMenu>
 );
};

export default OtherRoutes;
