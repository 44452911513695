export const tipositem = [
    { value: '00', label: 'Mercadoria para Revenda' },
    { value: '01', label: 'Matéria Prima' },
    { value: '02', label: 'Embalagem' },
    { value: '03', label: 'Produto em Processo' },
    { value: '04', label: 'Produto Acabado' },
    { value: '05', label: 'Subproduto' },
    { value: '06', label: 'Produto Intermediário' },
    { value: '07', label: 'Material de Uso e Consumo' },
    { value: '08', label: 'Ativo Imobilizado' },
    { value: '09', label: 'Serviços' },
    { value: '10', label: 'Outros Insumos' },
    { value: '99', label: 'Outras' },
    { value: 'KT', label: 'Kit' },
]

/*
00 - Mercadoria para Revenda
01 - Matéria Prima
02 - Embalagem
03 - Produto em Processo
04 - Produto Acabado
05 - Subproduto
06 - Produto Intermediário
07 - Material de Uso e Consumo
08 - Ativo Imobilizado
09 - Serviços
10 - Outros Insumos
99 - Outras
KT - Kit
*/

export const situacaoCliente = [
    { value: 'A', label: 'Ativo' },
    { value: 'I', label: 'Inativo' },
    { value: 'E', label: 'Excluido' },
]

export const situacaoProduto = [
    { value: 'A', label: 'Ativo' },
    { value: 'I', label: 'Inativo' },
]

export const tipoProduto = [
    { value: 'P', label: 'Produto' },
    { value: 'S', label: 'Serviço' },
]

export const tipoProducao = [
    { value: 'P', label: 'Própria' },
    { value: 'T', label: 'Terceiros' },
]

export const indicadorIe = [
    { value: '1', label: 'Contribuinte ICMS' },
    { value: '2', label: 'Contribuinte isento de Inscrição' },
    { value: '9', label: 'Não Contribuinte' },
]

export const origens = [
    { value: '0', label: 'Nacional: exceto as indicadas nos códigos 3, 4, 5 e 8.' },
    { value: '1', label: 'Estrangeira: importação direta, exceto a indicada no código 6.' },
    { value: '2', label: 'Estrangeira: adquirida no mercado interno, exceto a indicada no código 7.' },
    { value: '3', label: 'Nacional: mercadoria ou bem com conteúdo de importação superior a 40% e inferior ou igual a 70%.' },
    { value: '4', label: 'Nacional: cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei n.º 288/1967, e as Leis n.º 8.248/1991, 8.387/1991, 10.176/2001 e 11.484/2007.' },
    { value: '5', label: 'Nacional: mercadoria ou bem com Conteúdo de Importação inferior, ou igual a 40%.' },
    { value: '6', label: 'Estrangeira: importação direta, sem similar nacional, constante em lista de Resolução Camex e gás natural.' },
    { value: '7', label: 'Estrangeira: adquirida no mercado interno, sem similar nacional, constante em lista de Resolução Camex e gás natural.' },
    { value: '8', label: 'Nacional: mercadoria ou bem com Conteúdo de Importação superior a 70%.' },
]

/*
0 — Nacional: exceto as indicadas nos códigos 3, 4, 5 e 8.
1 — Estrangeira: importação direta, exceto a indicada no código 6.
2 — Estrangeira: adquirida no mercado interno, exceto a indicada no código 7.
3 — Nacional: mercadoria ou bem com conteúdo de importação superior a 40% e inferior ou igual a 70%.
4 — Nacional: cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei n.º 288/1967, e as Leis n.º 8.248/1991, 8.387/1991, 10.176/2001 e 11.484/2007.
5 — Nacional: mercadoria ou bem com Conteúdo de Importação inferior, ou igual a 40%.
6 — Estrangeira: importação direta, sem similar nacional, constante em lista de Resolução Camex e gás natural.
7 — Estrangeira: adquirida no mercado interno, sem similar nacional, constante em lista de Resolução Camex e gás natural.
8 — Nacional: mercadoria ou bem com Conteúdo de Importação superior a 70%.
*/


const categoriasPA = [
    {
        "id": 328731,
        "descricao": "Acessórios",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 328737,
        "descricao": "Ceras e Polidores",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 378469,
        "descricao": "Boinas",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 387070,
        "descricao": "Matéria Prima",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 410926,
        "descricao": "Embalagens",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 4241140,
        "descricao": "Máquinas",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 4241891,
        "descricao": "Impermeabilizantes",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 6375771,
        "descricao": "Rótulos",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 6940238,
        "descricao": "Higienização",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 7674721,
        "descricao": "Caixas",
        "categoriaPai": {
            "id": 0
        }
    }
]

const categoriasSQ = [
    {
        "id": 5368200,
        "descricao": "Acessórios",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 5368204,
        "descricao": "Boinas",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 5368206,
        "descricao": "Ceras e Polidores",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 5368208,
        "descricao": "Impermeabilizantes",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 5368213,
        "descricao": "Máquinas",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 5384117,
        "descricao": "Matéria Prima",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 5384133,
        "descricao": "Embalagens",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 5384166,
        "descricao": "Rótulos",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 6941278,
        "descricao": "Higienização",
        "categoriaPai": {
            "id": 0
        }
    },
    {
        "id": 7674763,
        "descricao": "Caixas",
        "categoriaPai": {
            "id": 0
        }
    }
]

export const categoriasProdutos = categoriasPA.map(categoria => {
    return {
        empresaId: 1,
        id: categoria.id,
        descricao: categoria.descricao,
        categoriaPai: categoria.categoriaPai.id
    }
}).concat(categoriasSQ.map(categoria => {
    return {
        empresaId: 2,
        id: categoria.id,
        descricao: categoria.descricao,
        categoriaPai: categoria.categoriaPai.id
    }
}))
