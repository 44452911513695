import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Space, Typography } from 'antd';
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { apierp } from '../../services/api';
import { customMessage } from '../../components/template/CustomMessage';

export default function Profile() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  // console.log(usuario)
  
  const mcp = usuario.mustChangePassword ? "Obrigatório a mudança de senha!" : ""
  let navigate = useNavigate();

  const [formLogin] = Form.useForm();
  const [buttonsEnabled, setButtonsEnabled] = React.useState(true);
  const [msgError, setMsgError] = useState('')

  useEffect(() => {
    setMsgError(mcp)
  }, [])

  function enableButtons(wait: number) {
    setTimeout(function () {
      setButtonsEnabled(true)
      setMsgError('')
    }, wait);
  }

  function disableButtons() {
    setButtonsEnabled(false)
  }
  
  function handleCancelClick() {
    // if(mcp) return
    navigate('/')
    // setMenuSelected(0);
    // hideMenu()
    // setTimeout(() => { navigate('/profile') }, 180);
  }

  async function handleSubmitChangePassword(dados: any) {
    setButtonsEnabled(false)

    console.log(dados)
    if (dados.newpassword !== dados.confirmPassword) {
      setMsgError('As duas senhas são diferentes!')
      enableButtons(2500)
      return
    }
    
    const body = {
      id: usuario.id,
      newpassword: dados.newpassword,
      mustChangePassword: false
    }

      try {
      const ret = await apierp.put(`changepwd`, body);
      message.success('Perfil alterado com sucesso!');
      navigate('/')
    } catch (err) {
      if (customMessage(err)===403) Logout()
      enableButtons(2500)
    }

    // if (ret.status !== 0) {
    //   setMsgError(ret.message)
    //   enableButtons(2500)
    // } else {
    //   // formLogin.resetFields();
    //   // setMenuSelected(0);
    //   // route.push('/')
    // }
  };

  return (
    <div className="min-h-screen flex flex-row justify-center items-center">
      <div >
        <div title="" className="bg-blue-100 shadow-lg rounded-lg w-[320px]" >
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold text-center my-4">Seu Profile</h1>
            <div className="flex mt-4 px-4">
              <span className="text-base font-base w-[100px]">Nome:</span>
              <span className="text-lg font-semibold">{usuario.name}</span>
            </div>
            <div className="flex px-4">
              <span className="text-base font-base w-[100px]">Nome Curto:</span>
              <span className="text-lg font-semibold">{usuario.shortName}</span>
            </div>
            <div className="flex flex-col px-4">
              <span className="text-base font-base w-[100px]">Email:</span>
              <span className="text-lg ml-4 font-semibold">{usuario.email}</span>
            </div>
          </div>
            <h1 className="text-xl font-bold text-center mb-4 mt-8">Alterar Senha</h1>
          <Form
            layout="vertical" form={formLogin} onFinish={handleSubmitChangePassword} >
            <div className='px-4 pb-4'>
            {/* <Form.Item name="email" hidden noStyle></Form.Item> */}

              <Form.Item className='mt-4'
                name="newpassword"
                label="Nova Senha:"
                rules={[{ required: true, message: 'Senha é obrigatória' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item className='xmt-4'
                name="confirmPassword"
                label="Confirme Senha:"
                rules={[{ required: true, message: 'Confirmação é obrigatória' }]}
              >
                <Input.Password />
              </Form.Item>
              <div className={msgError ? "bg-red-700 min-h-[24px] px-4 mb-2 font-semibold text-white" : "bg-blue-100 min-h-[24px] px-4 mb-2 font-semibold text-white"}>
                {msgError}
              </div>
              <div className="flex justify-center">
                <Button className="w-28 mx-4 bg-yellow-600 text-white" onClick={handleCancelClick} disabled={!buttonsEnabled} >Agora não!</Button>
                <Button className="w-28 mx-4" onClick={formLogin.submit} disabled={!buttonsEnabled} type="primary">Alterar</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )

}
