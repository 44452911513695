import { format, parseISO, formatISO, differenceInDays, toDate, add, addHours, addDays, addMonths, addYears} from 'date-fns'

export function dateOnly(date) {
  // console.log('dateOnlyAntes', date);
  return `${format(date, 'yyyy-MM-01')}`;
}
export function dateFormat(date, formato="yyyy-MM-dd HH:mm:ss") {
  // console.log('dateFunction', date)
  if (typeof date === 'string') date=addHours(parseISO(date), -3)
  // console.log('date dateFormat', date)
  return `${format(date, formato)}`;
}

export function dateFormatBr(dateJson, formato='dd/MM/yyyy') {
  if (!dateJson) return "";
  return `${format(parseISO(dateJson), formato)}`;
}

export function dateMySqlToPtBr(dateStr) {
  // console.log('dateStr', dateStr)
  if (!dateStr || dateStr === '') { return '' }
  const tmp=dateStr.substring(0,10).split('-')
  return `${tmp[2]}/${tmp[1]}/${tmp[0]}`;
}

export function datePtBrToMySql(dateStr) {
  const tmp=dateStr.split('/')
  return `${tmp[2]}-${tmp[1]}-${tmp[0]}`;
}

export function nextMes(strDateOnly) {
  let next = strDateOnly.split('-');
  if (next[1] === '12') {
    next[1] = '01';
    next[0] = (Number(next[0]) + 1).toString();
  } else {
    next[1] = (Number(next[1]) + 101).toString().substring(1, 3);
  }
  next = next.join('-');
  // console.log('nextMes antes e após', strDateOnly, next);
  return next;
}

export function addDaysToStrDate(strDate, days) {
  const newDate = add(toDate(parseISO(strDate + ' 12:00:00')), {days: days})
  // return newDate;
  // newDate.setDate(newDate.getDate() + days);
  return format(newDate, 'yyyy-MM-dd');
}


// SUGERIDOS PELO COPILOT
export function differenceInDaysBetweenDates(date1, date2) {
  const parsedDate1 = parseISO(date1);
  const parsedDate2 = parseISO(date2);

  return differenceInDays(parsedDate1, parsedDate2);
}

export function addDaysToDate(date, days) {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  const newDate = addDays(parsedDate, days);

  return formatISO(newDate, { representation: 'date' });
}

export function addMonthsToDate(date, months) {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  const newDate = addMonths(parsedDate, months);

  return formatISO(newDate, { representation: 'date' });
}

export function addYearsToDate(date, years) {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  const newDate = addYears(parsedDate, years);

  return formatISO(newDate, { representation: 'date' });
}