import { Button, Space } from "antd"
import { LockFilled, UnlockFilled, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined } from '@ant-design/icons';
import Tabela from "../../../../components/template/Tabela"
import { copyToClipboard, downloadToCsv, printDiv } from "../../../../utils/functions"
import { useGeral } from "../../../../contexts/geral";
import { columnsMovimentoEstoque } from "./columnsMovimentoEstoque";
import { dateFormat } from "../../../../utils/dateFunctions";
import isPermitted from "../../../../hooks/isPermitted";

export function MovimentosEstoque(props: any) {
  const { userTenant } = useGeral();
  const colors = userTenant.tenant.colors.split(',')

  const isPermittedVisualizarPrecosProdutos = isPermitted('visualizar_precos_produtos')
  const isPermittedVisualizarNomesProdutos = isPermitted('visualizar_nomes_produtos')
  const isPermittedManterEstoques = isPermitted('manter_estoques')

  const { produto, novaMovimentoEstoque,
    handleRowMovimentoEstoque, rowMovimentoEstoqueSelected, lastMovimentoEstoqueSelected, loadingMovimentoEstoque, filterDate
  } = props

  // if (!produto) { return <div>Sem Movimentos Estoque</div> }

  // console.log('movimentosestoque',produto.fichastecnicas)
  const movimentosestoque = produto.movimentosestoque

  // if (!movimentosestoque[0] && movimentosestoque.length === 1) { 
  //   return <div>Sem Movimentos Estoque</div> 
  // }

  // console.log(dateFormat(filterDate, '01/MM/yyyy'), '\n',movimentosestoque)
  const dadosFlat: any = []
  for (let index = 0; index < movimentosestoque.length; index++) {
    let it: any = movimentosestoque[index]
    // console.log('it', it)
    if (it) { // caso it seja null, pula
      if (index === 0) {
        it.data = dateFormat(filterDate, 'yyyy-MM-01')
        it.tipomovimento.nome = 'Saldo anterior'
        it.quantidade = ''
        it.vlrUnitario = ''
        it.operacao = ''
        it.motivo=''
        it.observacao = ''
        it.updatedBy = ''
        it.movimento = ''
      }
      const item = {
        ...it,
        tipomovimentoNome: it.tipomovimento.nome,
        notaFiscal: it.notaFiscal === null ? '' : it.notaFiscal,
        motivo: it.motivo.nome,
        addClass:
          index === 0 ? "bg-[#FFEEEE] italic font-semibold" :
            it.movimento === 1 ? "bg-[#EEFFEE]" :
              it.movimento === 2 ? "bg-[#FEFEFE]" : ""
      }
      dadosFlat.push(item)
    }
  }
  const colunas = columnsMovimentoEstoque.slice()
  if (!isPermittedVisualizarPrecosProdutos) {
    colunas.splice(6, 2)
    colunas.splice(4, 1)
  }
  const nomeProduto = isPermittedVisualizarNomesProdutos ? produto.descricao : produto.nomeComponente
  const wTab = isPermittedVisualizarPrecosProdutos ? 1938 : 1938 - 300
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)`
  return (
    <div className="flex flex-col justify-between" style={{ height: "calc(100vh - 137px)" }}>
      <Tabela className="mx-0" id='movimentoestoque' titulo='Movimento Estoque' subtitulo={`${produto.codigo} - ${nomeProduto} (${produto.unidade})`} wTab={wTab} htb={htb} hsc={hsc} dataSource={dadosFlat} columns={colunas} handleRow={handleRowMovimentoEstoque} rowSelected={rowMovimentoEstoqueSelected} lastSelected={lastMovimentoEstoqueSelected} loading={loadingMovimentoEstoque} />
      <div className={"pageButtons"} style={{ backgroundColor: colors[3] }}>
        <Space size={10}>
          <Button onClick={() => printDiv('printableDiv-movimentoestoque', 'movimentoestoque')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
          <Button onClick={() => copyToClipboard('movimentoestoque')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
          <Button onClick={() => downloadToCsv('movimentoestoque', "Inquilinos.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
          <Button onClick={novaMovimentoEstoque} type="primary" shape="round" size="small" icon={<PlusOutlined />} hidden={!isPermittedManterEstoques} title="Novo"></Button>
        </Space>
      </div>
    </div>
  )
}
