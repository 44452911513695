import { useMutation, useQueryClient } from "@tanstack/react-query";
import { categoriasProdutos } from "../../../data/dados";
import { alterarProdutoBLING } from "./functions";
import { cadastrarProdutoBLING } from "./functions";
import { apierp } from "../../../services/api";

const submit = async ({ dados, idsBling, op }: { dados: any, idsBling: any, op: string }) => {
    // const { dados, op, idsBling } = data
    const idsCat = categoriasProdutos
        .filter((c: any) => c.descricao === dados.categoria)
        .map((c: any) => { return { empresaId: c.empresaId, id: c.id, descricao: c.descricao } })

    // console.log('dados', dados)

    const produtoBLINGbase = {
        nome: dados.nome.toUpperCase().trim(),
        tipo: dados.tipo,
        situacao: dados.situacao[0],
        formato: 'S',
        id: null,
        codigo: dados.codigo,
        preco: dados.precoVenda.toString().replace(',', '.'),
        unidade: dados.unidade,
        pesoLiquido: dados.pesoLiquido.toString().replace(',', '.'),
        pesoBruto: dados.pesoBruto.toString().replace(',', '.'),
        tipoProducao: dados.producao,
        categoria: {
            id: null
        },
        dimensoes: {
            largura: dados.largura.toString().replace(',', '.'),
            altura: dados.altura.toString().replace(',', '.'),
            profundidade: dados.profundidade.toString().replace(',', '.'),
            unidadeMedida: 1
        },
        tributacao: {
            origem: dados.origem,
            ncm: dados.ncm,
            spedTipoItem: dados.spedTipoItem,
        },
    }
    const blingProdutoZAPPbase = {
        idbling: null,
        tenantId: 3,
        empresaId: null,
        codigo: dados.codigo,
        nome: dados.nome,
        tipo: dados.tipo,
        producao: dados.producao,
        origem: dados.origem,
        descricao: dados.nome,
        situacao: dados.situacao,
        unidade: dados.unidade,
        preco: dados.precoVenda.toString().replace(',', '.'),
        spedTipoItem: dados.spedTipoItem,
        categoriaId: null,
        categoria: dados.categoria,
        grupoProduto: null,
        pesoLiquido: dados.pesoLiquido.toString().replace(',', '.'),
        pesoBruto: dados.pesoBruto.toString().replace(',', '.'),
        largura: dados.largura.toString().replace(',', '.'),
        altura: dados.altura.toString().replace(',', '.'),
        profundidade: dados.profundidade.toString().replace(',', '.'),
        ncm: dados.ncm,
        produtoId: dados.id,
    }

    let id1 = idsBling.find((c: any) => c.empresaId === 1)?.idbling || ''
    let id2 = idsBling.find((c: any) => c.empresaId === 2)?.idbling || ''
    const cat1 = idsCat.find((c: any) => c.empresaId === 1)?.id
    const cat2 = idsCat.find((c: any) => c.empresaId === 2)?.id
    // const cat1Desc = idsCat.find((c: any) => c.empresaId === 1)?.descricao
    // const cat2Desc = idsCat.find((c: any) => c.empresaId === 2)?.descricao

    const produtoBLING1 = {
        ...produtoBLINGbase,
        // id: op === 'A' ? idsBling.find((c: any) => c.empresaId === 1)?.idbling || '' : '',
        id: id1,
        categoria: { id: cat1 },
    }
    const produtoBLING2 = {
        ...produtoBLINGbase,
        // id: op === 'A' ? idsBling.find((c: any) => c.empresaId === 2)?.idbling || '' : '',
        id: id2,
        categoria: { id: cat2 },
    }

    // console.log('Antes : id1', id1, 'id2', id2)

    // console.log('produtoBLING1', produtoBLING1)
    // console.log('produtoBLING1.ID', !!produtoBLING1.id)
    // console.log('antes de alterar produtoBLING1')
    if (id1) {
        await alterarProdutoBLING(produtoBLING1, 1, id1)
    } else {
        id1 = await cadastrarProdutoBLING(produtoBLING1, 1)
    }
    // console.log('antes de alterar produtoBLING2')
    if (id2) {
        await alterarProdutoBLING(produtoBLING2, 2, id2)
    } else {
        id2 = await cadastrarProdutoBLING(produtoBLING2, 2)
    }

    // console.log('Depois: id1', id1, 'id2', id2)
    const blingProdutoZAPP1 = {
        ...blingProdutoZAPPbase,
        idbling: id1,
        empresaId: 1,
        preco: blingProdutoZAPPbase.preco === '' ? null : blingProdutoZAPPbase.preco,
        pesoBruto: blingProdutoZAPPbase.pesoBruto === '' ? null : blingProdutoZAPPbase.pesoBruto,
        pesoLiquido: blingProdutoZAPPbase.pesoLiquido === '' ? null : blingProdutoZAPPbase.pesoLiquido,
        largura: blingProdutoZAPPbase.largura === '' ? null : blingProdutoZAPPbase.largura,
        altura: blingProdutoZAPPbase.altura === '' ? null : blingProdutoZAPPbase.altura,
        profundidade: blingProdutoZAPPbase.profundidade === '' ? null : blingProdutoZAPPbase.profundidade,
        categoriaId: cat1,
    }
    const blingProdutoZAPP2 = {
        ...blingProdutoZAPPbase,
        idbling: id2,
        empresaId: 2,
        preco: blingProdutoZAPPbase.preco === '' ? null : blingProdutoZAPPbase.preco,
        pesoBruto: blingProdutoZAPPbase.pesoBruto === '' ? null : blingProdutoZAPPbase.pesoBruto,
        pesoLiquido: blingProdutoZAPPbase.pesoLiquido === '' ? null : blingProdutoZAPPbase.pesoLiquido,
        largura: blingProdutoZAPPbase.largura === '' ? null : blingProdutoZAPPbase.largura,
        altura: blingProdutoZAPPbase.altura === '' ? null : blingProdutoZAPPbase.altura,
        profundidade: blingProdutoZAPPbase.profundidade === '' ? null : blingProdutoZAPPbase.profundidade,
        categoriaId: cat2,
    }
    // console.log('blingProdutoZAPP1', blingProdutoZAPP1)
    // console.log('blingProdutoZAPP2', blingProdutoZAPP2)
    const response1 = await apierp.post(`produtos/createorupdateblingproduto`, blingProdutoZAPP1)
    // console.log(response1)
    const response2 = await apierp.post(`produtos/createorupdateblingproduto`, blingProdutoZAPP2)
    // console.log(response2)

    return 'OK'

}

export function useMutateMapa() {
    const queryClient = useQueryClient()
    const mutate = useMutation({
        mutationFn: submit,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['produtosbling']})
            queryClient.invalidateQueries({ queryKey: ['blingprodutos']})
            queryClient.invalidateQueries({ queryKey: ['produtos']})
            queryClient.invalidateQueries({ queryKey: ['produto']})
        }
    })

    return mutate
}