import { Button, Drawer, Input, Modal, Select, Space, Typography } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { TbArrowBigRightLines, TbArrowBigDownLines, TbArrowBigLeftLines, TbArrowBigUpLines } from "react-icons/tb";

import Layout from "../../../components/template/Layout";
import SpinnerNew from "../../../components/template/SpinnerNew";
import useWindowSize from "../../../hooks/useWindowSize";

import isPermitted from "../../../hooks/isPermitted";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { apierp } from "../../../services/api";
import { validateCPFOrCNPJ } from "../../../utils/functions";
import useClientes from "./useClientes";
import { CardClienteThin } from "../../__Shared/Clientes/CardClienteThin";
import { comparar, comparar2Clientes } from "./functions";
import ModalSincronizarBling from "./ModalSincronizarBling";
import DrawerFormNewCliente from "./DrawerFormNewCliente";
import DrawerFormAlteraCliente from "./DrawerFormAlteraCliente";

const { Title, Text, Paragraph } = Typography;

export default function MapaCliente() {

    const isPermittedManterClientes = isPermitted('manter_clientes')
    const [text, setText] = useState(null as any);
    const [textInput, setTextInput] = useState(null as any);
    const [params, setParams] = useState(null as any);
    const [retorno, setRetorno] = useState(null as any);
    const [empresas, setEmpresas] = useState([])
    const [showModalSincronizarBling, setShowModalSincronizarBling] = useState(false)
    const [drawerFormNewClienteVisible, setDrawerFormNewClienteVisible] = useState(false)
    const [drawerFormAlteraClienteVisible, setDrawerFormAlteraClienteVisible] = useState(false)
    const [clientesToSync, setClientesToSync] = useState(null as any)
    const [clisel, setClisel] = useState(null as any)

    const [showMapa, setShowMapa] = useState(false)
    const [termo, setTermo] = useState(null as any);
    const [valueDebounce] = useDebounce(termo, 500);

    const { clientesBling, clientesBlingZapp, clienteZapp, processando, reload, reloadClientesBlingZapp } = useClientes(params)
    useEffect(() => {
        loadEmpresas()
    }, [])

    async function loadEmpresas() {
        const response = await apierp.get('empresas')
        const data = response.data as any;
        setEmpresas(data);
    }

    async function loadClientes(dados: any) {
        const response = await apierp.post(`clientes/obterclientesportermo`, dados)
        const data = await response.data as any;
        setRetorno(data)
        return data
    }

    const novoCliente = () => {
        setDrawerFormNewClienteVisible(true)
    }

    function alterarCliente(empresaId: any) {
        // console.log('alteraCliente', empresaId)
        setClisel(clientesBling[empresaId-1])
        setDrawerFormAlteraClienteVisible(true)
    }

    useEffect(() => {
        if (valueDebounce) {
            const dados = { termo: valueDebounce, limit: 50 }
            loadClientes(dados)
        }
    }, [valueDebounce])


    const handleSearch = (newValue: string) => {
        if (newValue.length < 3) {
            setShowMapa(false)
            setTermo(null)
            setRetorno(null)
            return
        }
        setTermo(newValue);
    };

    const handleChange = async (newValue: string) => {
        // newValue = id do cliente
        const cliente = await retorno?.clientes.find((c: any) => c.id === newValue)
        setTextInput(cliente?.cnpj)
        setText(cliente?.cnpj)
        setShowMapa(false)
        pesquisar2(cliente?.cnpj)
    };

    const handleTextInput = (e: any) => {
        setTextInput(e.target.value)
        setText(e.target.value.replace(/[^\d]+/g, ''))
        setShowMapa(false)
    }

    const pesquisar = () => {
        // verificar se texto é cpf ou cnpj valido
        if (validateCPFOrCNPJ(text) === false) {
            Modal.error({ title: 'Erro', content: 'Cnpj/Cpf inválido', });
            return
        }
        pesquisar2(text)
    }

    const pesquisar2 = (cnpj: any) => {
        const dados = { empresas, documento: cnpj }
        setParams(dados)
        setShowMapa(true)
    }

    const hideModalSincronizarBling = () => {
        setShowModalSincronizarBling(false)
    };

    const hideDrawerFormNewCliente = () => {
        setDrawerFormNewClienteVisible(false)
    }
    const hideDrawerFormAlteraCliente = () => {
        setDrawerFormAlteraClienteVisible(false)
    }

    function b1toB2() {
        setClientesToSync({ clienteFrom: clientesBling[0], clienteTo: clientesBling[1], blingTo: empresas[1] })
        setShowModalSincronizarBling(true)
    }
    function b2toB1() {
        setClientesToSync({ clienteFrom: clientesBling[1], clienteTo: clientesBling[0], blingTo: empresas[0] })
        setShowModalSincronizarBling(true)
    }
    async function b1toBZ() {
        const dadosDto = clientesBling[0]
        // console.log('dadosDto', dadosDto)
        const response = await apierp.post(`clientes/createorupdateblingcliente`, dadosDto)
        // const data = response.data as any;
        reloadClientesBlingZapp()
    }
    async function b2toBZ() {
        const dadosDto = clientesBling[1]
        // console.log('dadosDto', dadosDto)
        const response = await apierp.post(`clientes/createorupdateblingcliente`, dadosDto)
        // const data = response.data as any;
        reloadClientesBlingZapp()
    }

    //componentes
    const B1toB2 = (props: any) => {
        return (<Button disabled={props.disabled} hidden={props.hidden} className="h-[26px] px-2 pt-[0px] pb-[2px]" onClick={() => b1toB2()} icon={<TbArrowBigRightLines className='text-2xl ' />} title="Copiar para B2"> </Button>)
    }
    const B2toB1 = (props: any) => {
        return (<Button disabled={props.disabled} hidden={props.hidden} className="h-[26px] px-2 pt-[0px] pb-[2px]" onClick={() => b2toB1()} icon={<TbArrowBigLeftLines className='text-2xl ' />} title="Copiar para B1"> </Button>)
    }
    const B1toBZ = (props: any) => {
        return (<Button disabled={props.disabled} hidden={props.hidden} className="h-[26px] px-2 pt-[0px] pb-[2px]" onClick={() => b1toBZ()} icon={<TbArrowBigDownLines className='text-2xl ' />} title="Copiar para BZ"> </Button>)
    }
    const B2toBZ = (props: any) => {
        return (<Button disabled={props.disabled} hidden={props.hidden} className="h-[26px] px-2 pt-[0px] pb-[2px]" onClick={() => b2toBZ()} icon={<TbArrowBigDownLines className='text-2xl ' />} title="Copiar para BZ"> </Button>)
    }


    let [innerW, innerH] = useWindowSize()
    const wMain = innerW > 640 ? innerW - 144 : innerW // 1308
    // const hMain = innerH - 60 - 78 - (32 + 12 - 32 - 12) - 40 - 32 - 8
    const hMain = innerH - (60 + 78 + (38 + 8) + 40 + 32 + 8)
    const wContent = 2 * (560 + 8) + 4 // wMain - 32 // 32
    // Main Header + Main Title + (Title + Margin) + Button Bar + Main Footer  wscrollbar + 8

    const cliZapp = clienteZapp[0] as any
    const bg = ['bg-purple-800', 'bg-[#293C61]', 'bg-blue-600']
    const bc = ['border-purple-800', 'border-[#293C61]', 'border-blue-600']

    let difBZ = [] as any
    let difBB = [] as any

    const comparacaoBZ = comparar(clientesBling, clientesBlingZapp);
    const comparacaoBB = comparar2Clientes(clientesBling[0], clientesBling[1])

    if (comparacaoBZ.length > 0) {
        for (let i = 0; i < 2; i++) {
            const dif = [] as any
            for (let cBZ of comparacaoBZ[i]) {
                dif.push(cBZ.k)
            }
            difBZ.push(dif)
        }
    }

    if (comparacaoBB.length > 0) {
        comparacaoBB.map((cBB: any) => difBB.push(cBB.k))
    }

    difBZ = difBZ.map((d: any) => JSON.stringify(d).replace(/[\[\]"]/g, '').replace(/,/g, ', '))
    difBB = JSON.stringify(difBB).replace(/[\[\]"]/g, '').replace(/,/g, ', ')
    if (difBZ.length > 0) {
        if (difBZ[0].substring(0, 4) === 'cnpj') { difBZ[0] = 'cnpj, ...' }
        if (difBZ[1].substring(0, 4) === 'cnpj') { difBZ[1] = 'cnpj, ...' }
        if (difBB.substring(0, 4) === 'cnpj') { difBB = 'cnpj, ...' }
    }

    const clientesBling0: any = clientesBling[0]
    const clientesBling1: any = clientesBling[1]
    const clientesBlingZapp0: any = clientesBlingZapp[0]
    const clientesBlingZapp1: any = clientesBlingZapp[1]

    const disableB1toB2 = difBB === '' || !clientesBling0?.cnpj || (clientesBling0?.situacao === 'E' && !clientesBling1?.cnpj) // || clientesBling0?.situacao !== 'A'
    const disableB2toB1 = difBB === '' || !clientesBling1?.cnpj || (clientesBling1?.situacao === 'E' && !clientesBling0?.cnpj) // || clientesBling1?.situacao !== 'A'
    const disableB1toBZ = difBZ[0] === ''
    const disableB2toBZ = difBZ[1] === ''
    const haveDiff = difBB !== '' || difBZ[0] !== '' || difBZ[1] !== ''
    const wCard = 'w-[560px]'
    const disabledButton = text?.length !== 11 && text?.length !== 14
    const enabledNovo = clientesBling.length === 2 && clientesBling[0]?.cnpj === undefined && clientesBling[1]?.cnpj === undefined
    const botoes = () => {
        return (
            <div>
                <Button disabled={!enabledNovo} onClick={novoCliente} type="primary" shape="round" size="small" icon={<PlusOutlined />} hidden={!isPermittedManterClientes} title="Nova"> Novo Cliente</Button>
            </div>
        )
    }

    const idS = [clientesBling[0]?.id, clientesBling[1]?.id]

    return (
        <Layout titulo={"Mapa do Cliente"} botoes={botoes()}>
            <div className="select flex mx-auto mb-3 items-center" >
                <Select
                    // allowClear
                    showSearch
                    value={termo}
                    placeholder={'Digite parte do nome ou inicio do CNPJ/CPF'}
                    style={{ width: '312px' }}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    notFoundContent={null}
                    popupClassName="border-solid border rounded border-blue-500"
                    options={(retorno?.clientes || []).map((d: any) => ({
                        value: d.id,
                        label: d.nome,
                    }))}
                />
                <div className="ml-2">{'Cnpj/Cpf:'}</div>
                <Input className='ml-2 w-[160px]' value={textInput} onChange={(e) => handleTextInput(e)} />
                <Button className="ml-2" disabled={disabledButton || processando } onClick={pesquisar} type="primary" shape="round" size="small" title="Nova">Pesquisar</Button>
            </div>
            <SpinnerNew loading={processando} />

            <div hidden={processando || !showMapa} className="conteudo overflow-x-auto bg-lime-4000">
                <div className="wmain flex flex-col" style={{ maxWidth: `${wMain - 8}px`, width: `${wMain - 8}px`, height: `${hMain}px`, maxHeight: `${hMain}px` }}>
                    <div className={`blings flex gap-0 w-[${wContent}px] mx-auto `}>
                        {clientesBling.map((cliente: any, index: number) => {
                            return (
                                <>
                                    <div className={`colpa p-[2px]  mx-1 ${bg[cliente.empresaId]}`}>
                                        <div className="empresa p-1 font-bold bg-white text-center">{cliente.empresaNome.toUpperCase()}</div>
                                        {/* <div className="flex "> */}
                                        {/* <div className="w-40 pt-[2px] mt-2 bg-white flex justify-evenly">{index === 1 ? <><AtivarB2 /><DesativarB2 /></> : null}</div> */}
                                        <div className="fonte w-full p-1 mt-2 font-semibold bg-white text-center">BLING</div>
                                        {/* <div className="w-40 pt-[2px] mt-2 bg-white flex justify-evenly">{index === 0 ? <><AtivarB1 /><DesativarB1 /></> : null}</div> */}
                                        {/* </div> */}
                                        <CardClienteThin className='mt-[2px] bg-white' cliente={cliente} wCard={wCard} diff={difBB !== ''} alterarCliente={alterarCliente} />
                                        <div className="p-1 mt-2 h-[38px] bg-white text-center">
                                            <div className="w-full pt-[2px] bg-white flex justify-evenly">{index === 0 ? <B1toBZ disabled={disableB1toBZ} hidden={!isPermittedManterClientes} /> : <B2toBZ disabled={disableB2toBZ} hidden={!isPermittedManterClientes} />}</div>
                                        </div>
                                    </div>
                                    <div className=' w-9 pt-24' hidden={index > 0}>
                                        <div className=" pt-[2px] bg-white " >
                                            {index === 0 ? <div className="flex flex-col gap-4"><B1toB2 disabled={disableB1toB2} hidden={!isPermittedManterClientes} /><B2toB1 disabled={disableB2toB1} hidden={!isPermittedManterClientes} /></div> : null}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className={`flex gap-0 w-[${wContent}px] mx-auto `}>
                        {clientesBlingZapp.map((cliente: any, index: number) => {
                            return (
                                <>
                                    <div className={`colpa p-[2px]  mx-1 ${bg[cliente?.empresaId]}`}>
                                        {/* <div className="flex "> */}
                                        {/* <div className="w-40 pt-[2px] mt-1 bg-white flex justify-evenly">{index === 1 ? <><AtivarZ2 /><DesativarZ2 /></> : null}</div> */}
                                        <div className="fonte w-full p-1 mt-1 font-semibold bg-white text-center">BLING ZAPP</div>
                                        {/* <div className="w-40 pt-[2px] mt-1 bg-white flex justify-evenly">{index === 0 ? <><AtivarZ1 /><DesativarZ1 /></> : null}</div> */}
                                        {/* </div> */}
                                        <CardClienteThin className='mt-[2px] bg-white' cliente={cliente} wCard={wCard} diff={difBZ[index] !== ''} />
                                    </div>
                                    <div className=' w-9 pt-20' hidden={index > 0}>
                                        <div className="flex flex-col pt-[2px] mt-2 bg-white " >{index === 0 ? null : null}</div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div hidden={processando || !haveDiff || !cliZapp} className={`comparacoes flex justify-between mt-1 mx-auto w-[${wContent}px] `} style={{ minWidth: `${wContent - 4 + 40}px` }}>
                        <div className="flex flex-col mx-1">
                            <div className="">Diferenças entre Bling Produtos Automotivos e Zapp</div>
                            <div className={` w-full p-2 border-2 border-solid border-r ${bc[1]} text-red-500 text-center`}>{difBZ[0]}</div>
                        </div>
                        <div className="flex flex-col">
                            <div className="">Diferenças entre Blings Produtos Automotivos e Soluções Químicas</div>
                            <div className={` w-full p-2 border-2 border-solid border-x ${bc[0]} text-red-500 text-center`}>{difBB}</div>
                        </div>
                        <div className="flex flex-col">
                            <div className="">Diferenças entre Bling Soluções Químicas e Zapp</div>
                            <div className={` w-full p-2 border-2 border-solid border-l ${bc[2]} text-red-500 text-center`}>{difBZ[1]}</div>
                        </div>

                    </div>
                    <div hidden={processando || !cliZapp} className={`clientezap p-[2px] mt-4 mx-auto  ${bg[cliZapp?.empresaIdRef] || 'bg-zinc-600'}`}>
                        {/* <div className="flex "> */}
                        {/* <div className="w-40 p-1 mt-0 bg-white"></div> */}
                        <div className="fonte w-full p-1  font-semibold bg-white text-center">ZAPP</div>
                        {/* <div className="w-40 p-1 mt-0 bg-white"></div> */}
                        {/* </div> */}
                        <CardClienteThin className='mt-1 bg-white' cliente={cliZapp} wCard={'w-[720px]'} />
                    </div>
                </div>
                <ModalSincronizarBling showModalSincronizarBling={showModalSincronizarBling} hideModalSincronizarBling={hideModalSincronizarBling} clientesToSync={clientesToSync} pesquisar={pesquisar} />
                <DrawerFormNewCliente hideDrawerFormNewCliente={hideDrawerFormNewCliente} drawerFormNewClienteVisible={drawerFormNewClienteVisible} cliente={{numeroDocumento: text}} pesquisar={pesquisar} />
                <DrawerFormAlteraCliente hideDrawerFormAlteraCliente={hideDrawerFormAlteraCliente} drawerFormAlteraClienteVisible={drawerFormAlteraClienteVisible} cliente={clisel} ids={idS} pesquisar={pesquisar} />
            </div>
        </Layout>
    )
}