import { useCallback, useEffect, useState } from "react";
import { Typography } from "antd";

import useDashboardVendas from "./useDashboardVendas";
import { dateFormat } from "../../../utils/dateFunctions";
import Layout from "../../../components/template/Layout";
import { Vendas } from "./vendas";
import Data from "../../../logic/utils/Data";
import { groupBy } from "../../../utils/functions";
import { Teste } from "./testeBar";

const { Title, Text, Paragraph } = Typography;

export default function DasboardVendas() {
    const [mesref, setMesRef] = useState(null as any)
    const [dates, setDates] = useState(null as any)

    const { dasboardVendas, empresas, processando, reload } = useDashboardVendas(mesref)

    const now = new Date()

    const qtMeses = 3
    const dateIn = new Date(new Date().setMonth(new Date().getMonth() - qtMeses));
    const dataInicial = dateFormat(Data.primeiroDia(dateIn), "yyyy-MM-dd")
    const dataFinal = dateFormat(Data.ultimoDia(new Date()), "yyyy-MM-dd")
    const dataHoje = dateFormat(new Date(), "yyyy-MM-dd")
    // console.log(dataHoje)
    // console.log(dataInicial)
    // console.log(dataFinal)

    useEffect(() => {
        // console.log('useEffect unico')
        setMesRef(now)
        if (!dates) {
            // console.log('setdates')
            setDates(createDateArray(new Date().getFullYear() - 1, 2)) // 2022, 2023
            // setDates(createDateArray(new Date().getFullYear()-0, 1)) // 2023, 2023
        }
    }, [])

    function createDateArray(year: number, years: number) {
        const days = 366 * years
        const lastyear = year + years - 1
        let arr = [];
        for (let i = 0; i < days; i++) {
            const date = new Date(year, 0, i + 1);
            if (date.getFullYear() <= lastyear) {
                const dataFormatada = dateFormat(date, 'yyyy-MM-dd')
                const dataSplit = dataFormatada.split('-')
                arr.push({
                    data: dataFormatada,
                    ano: dataSplit[0],
                    mes: dataSplit[1],
                    dia: dataSplit[2],
                })
            }
        }
        return arr;
    }
    const datas = dates ? dates.filter((x: any) => x.data >= dataInicial && x.data <= dataFinal) : []
    // console.log(datas)
    // console.log(dasboardVendas)

    const dados = dasboardVendas.filter((x: any) => x.data >= dataInicial && x.data <= dataFinal)
    const dadosGroup = groupBy(dados, ['data', 'ano', 'mes', 'dia'], ['totalVenda', 'totalNota'])
        .sort((a: any, b: any) => (a.data < b.data ? -1 : a.data === b.data ? 0 : 1));

    // console.log(dadosGroup)

    const dadosFinais = []
    for (let dt of datas) {
        const dado = dadosGroup.find((x: any) => x.data === dt.data)
        // console.log(dt.data, dado?.data)
        if (dado) {
            const dataSplit = dado.data.split('-')
            dadosFinais.push({
                data: dado.data,
                ano: dataSplit[0],
                mes: dataSplit[1],
                dia: dataSplit[2],
                anomes: dataSplit[0] + '/' + dataSplit[1],
                totalVenda: dado.totalVenda,
                totalNota: dado.totalNota,
            })
        } else if (dt.data <= dataHoje) {
            dadosFinais.push({
                data: dt.data,
                ano: dt.ano,
                mes: dt.mes,
                dia: dt.dia,
                anomes: dt.ano + '/' + dt.mes,
                totalVenda: 0,
                totalNota: 0,
            })
        }
    }

    const dias = groupBy(dadosFinais, ['dia'], ['totalVenda', 'totalNota'])
        .map((x: any) => x.dia)
    // console.log(dias)
    // console.log(dadosFinais)
    const anomeses = groupBy(dadosFinais, ['anomes'], ['totalVenda', 'totalNota'])
        .map((x: any) => x.anomes)
        // decrescente pois o primeiro aperece por cima
        .sort((a: any, b: any) => (a > b ? -1 : a.anomes === b.anomes ? 0 : 1)) // decrescente
    // console.log(anomeses)
    // console.log(dadosFinais)

    return (
        <Layout titulo={"Dashboard Vendas"} botoes={null} classContent={'xxxbg-slate-100'}>
            {/* <Title className="mx-auto" >{"A implementar"}</Title> */}
            <div className='flex flex-wrap w-full py-2 gap-3 justify-center'>
                <div className='w-[900px] h-[440px] mx-2 p-4 bg-white rounded-md border-solid border-2 border-slate-200'>
                    <Vendas titulo={`Vendas Diárias Acumuladas`} labels={dias} datasets={anomeses} dados={dadosFinais} />
                </div>
                {/* <div className='w-[900px] h-[440px] mx-2 p-4 bg-white rounded-md border-solid border-2 border-slate-200'>
                    <Teste titulo="Teste de Barras" />
                </div> */}
            </div>
        </Layout>
    )
}