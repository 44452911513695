import { useCallback, useEffect, useState } from "react";
import { addDays } from 'date-fns'
import Layout from "../../../components/template/Layout";
import usePedidosCompraPendentesEntradaByMonth from "./usePedidosCompraPendentesEntradaByMonth";
import { useAuth } from "../../../contexts/auth";
import DatePicker from "react-datepicker";
import useWindowSize from "../../../hooks/useWindowSize";
import { Button, Drawer, Form, Space, Typography, message } from "antd";
import { DownloadOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { dateFormat, dateMySqlToPtBr } from "../../../utils/dateFunctions";
import { useNavigate } from "react-router-dom";
import CardPedidosCompraPendentes from "./CardPedidosCompraPendentes";
import { apierp } from "../../../services/api";
import { customMessage } from "../../../components/template/CustomMessage";

const { Title, Text, Paragraph } = Typography;


export default function EntradaMercadoriasByMonth() {
  const [mesref, setMesRef] = useState(null as any)

  const { pedidosCompraPendentesEntrada, empresas, processando, reload } = usePedidosCompraPendentesEntradaByMonth(mesref)

  const now = new Date()
  useEffect(() => {
    // console.log('useEffect unico')
    setMesRef(now)
  }, [])

  // console.log(pedidosCompraPendentesEntrada)

  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const [drawerFormReceberEntregaVisible, setDrawerFormReceberEntregaVisible] = useState(false)
  const [rowPedidoCompraSelected, setRowPedidoCompraSelected] = useState(-1)

  const [formEntradaMercadoria] = Form.useForm();
  const [entradaDate, setEntradaDate] = useState(new Date(dateFormat(new Date(), 'yyyy-MM-dd 12:00:00')));

  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [toggleState, setToggleState] = useState(false)

  let navigate = useNavigate();

  // Drawers Form ReceberEntrega
  const showDrawerFormReceberEntrega = () => {
    setButtonsDisabled(false)
    setDrawerFormReceberEntregaVisible(true)
  };
  const hideDrawerFormReceberEntrega = async () => {
    setButtonsDisabled(false)
    setDrawerFormReceberEntregaVisible(false)
  };

  async function handleRow(id: any, index: any) {
    formEntradaMercadoria.resetFields()
    const pedidoCompraPendenteEntrada = pedidosCompraPendentesEntrada[index]
    setRowPedidoCompraSelected(index)

    const pedidoCompraPendente = {
      id,
      numero: pedidoCompraPendenteEntrada.numeropedido,
      data: pedidoCompraPendenteEntrada.datacompra,
      fornecedor: pedidoCompraPendenteEntrada.nomefornecedor,
      dataEntradaEstoque: new Date(),
      itens: pedidoCompraPendenteEntrada.itens,
    }

    formEntradaMercadoria.setFieldsValue(pedidoCompraPendente)

    showDrawerFormReceberEntrega()
  }

  const handleChangeDate = (newDate: any) => {
    // console.log(newDate, typeof newDate)
    setToggleState(!toggleState)
  }
  const dateChange = (date: any) => {
    setEntradaDate(date)
    // setHideButtonFinaliza(true)
  }

  function enableButtons(wait: number) {
    setTimeout(function () {
      setButtonsDisabled(false)
      // setMsgError('')
    }, wait);
  }
  function disableButtons() {
    setButtonsDisabled(true)
  }

  async function handleSubmitEntradaMercadoria(dadosForm: any) {
    // console.log(dadosForm)
    // disableButtons()
    const dados: any = {
      pedidoCompraId: dadosForm.id,
      dataEntradaEstoque: dateFormat(entradaDate, 'yyyy-MM-dd'),
    }

    try {
      const result = await apierp.put(`blingpedidoscomprav2/${dados.pedidoCompraId}`, dados);
      setToggleState(!toggleState)
      message.success('Entrada de Mercadoria registrada com sucesso!');
      hideDrawerFormReceberEntrega()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
      enableButtons(500)
    } finally {
      setRowPedidoCompraSelected(-1)
      reload()
      hideDrawerFormReceberEntrega()
    }
  }

  async function importarPedidosCompraV2() {
    const rota = "/zapp/" + usuario.shortName.replaceAll(' ', '_') // + "_" + userTenant.tenant.id
    // console.log(`${rota}/blingpedidoscomprav2`)
    navigate(`${rota}/blingpedidoscomprav2`)
  }

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => { importarPedidosCompraV2() }} type="primary" shape="round" size="small" icon={<DownloadOutlined />} hidden={!true} title="Buscar Pedidos de Compra">Buscar Pedidos de Compra</Button>
      </Space>
    )
  }
  const wTab = 740 // liquido = x-8
  const htb = `calc(100vh - 149px - 110px)`
  const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
  const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144

  let corPicker = 'bg-blue-600'
  if (mesref !== null) {
    corPicker = dateFormat(mesref, 'yyyy-MM') === dateFormat(now, 'yyyy-MM') ? 'bg-blue-600' : 'bg-red-600'
  }

  return (
    <Layout titulo={'Entrada de Mercadorias'} botoes={botoes()}>
      <div className="mx-auto">
        <DatePicker
          // wrapperClassName="btmonth"
          className={`w-[88px] mb-[3px] text-center uppercase border-none rounded-md font-medium py-1 cursor-pointer text-white ${corPicker}`}
          selected={mesref}
          locale="pt-BR"
          onChange={(date: any) => setMesRef(date)}
          dateFormat="MMM/yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          popperPlacement="bottom"
          maxDate={new Date()}
        />
      </div>
      <div className="overflow-x-auto">
        <div className="mx-auto" style={{ minWidth: wTab, maxWidth: wTab }}>
          <div className="flex flex-col items-center mb-4" style={{ minHeight: htb, maxHeight: htb }}>
            {pedidosCompraPendentesEntrada.map((pedido: any, index) =>
              <CardPedidosCompraPendentes className="" key={index} pedido={pedido} empresas={empresas} rowIndex={index} rowSelected={rowPedidoCompraSelected} handleRow={handleRow} />
            )}
          </div>
        </div>
      </div>

      {/* DRAWER FORM RECEBER ENTREGA */}
      <Drawer
        title={'Receber Entrega'}
        width={wDrawerx}
        closable={true}
        // maskClosable={false}
        onClose={hideDrawerFormReceberEntrega}
        open={drawerFormReceberEntregaVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="horizontal" form={formEntradaMercadoria} onFinish={handleSubmitEntradaMercadoria} >
            <Title level={5}>Pedido: {formEntradaMercadoria.getFieldValue('numero')} Data Compra: {formEntradaMercadoria.getFieldValue('itens') && dateMySqlToPtBr(formEntradaMercadoria.getFieldValue('data'))}</Title>
            <div className="font-semibold">Fornecedor: {formEntradaMercadoria.getFieldValue('fornecedor')}</div>
            <div className="my-2 font-semibold">Itens:</div>
            {formEntradaMercadoria.getFieldValue('itens') && formEntradaMercadoria.getFieldValue('itens').map((item: any, index: number) => {
              return (<div key={index} className="flex justify-between font-medium mr-4"><div>{item.codigo} - {item.descricao}</div> <div>{item.quantidade} {item.unidade}</div> </div>)
            })}
            <Form.Item name="id" hidden noStyle></Form.Item>
            <div className="font-medium">
              <Form.Item className="mt-4 w-[350px]"
                name="dataEntradaEstoque"
                label="Data de recebimento da entrega"
                labelCol={{ span: 18 }}
                rules={[{ required: true, message: 'Data Obrigatória' }]}
              >
                <DatePicker
                  className="ant-input w-[124px] text-lg text-red-500 font-bold "
                  selected={entradaDate}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => dateChange(date)}
                  todayButton="Hoje"
                  popperPlacement="bottom"
                  minDate={addDays(new Date(formEntradaMercadoria.getFieldValue('data')), 1)}
                  maxDate={new Date()}

                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <Space className="drawer-footer flex w-full justify-between">
          <Space className="flex w-full justify-start">
            <Button onClick={() => { hideDrawerFormReceberEntrega() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFormReceberEntrega}>Cancel</Button>
          </Space>
          <Button onClick={formEntradaMercadoria.submit} disabled={buttonsDisabled} type="primary">Submit</Button>
        </Space>
      </Drawer>

    </Layout>

  );
}