import { useEffect, useState } from "react"
import { LockFilled, StarOutlined, StarFilled, UnlockFilled, AndroidOutlined, CheckSquareFilled, DownloadOutlined, CopyOutlined, PrinterOutlined, PlusOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { Checkbox, message, Popconfirm, Radio, Table, Typography } from 'antd';
import { Drawer, Form, Button, Col, Row, Input, Select, Space } from 'antd';
import { copyToClipboard, downloadToCsv, groupBy, printDiv } from "../../utils/functions";
import { customMessage } from "../../components/template/CustomMessage";

import { useAuth } from '../../contexts/auth';
import { apierp } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useGeral } from "../../contexts/geral";

import Layout from "../../components/template/Layout";
import Spinner from "../../components/template/Spinner";

const { Search } = Input;
const { Title } = Typography;

export default function User() {
  const { user, Logout } = useAuth();
  const usuario: any = user;
  // console.log('usuario', usuario)
  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)
  let navigate = useNavigate();
  if (menu?.url !== '/users') navigate('/')

  const [form] = Form.useForm();
  const [formRole] = Form.useForm();
  const [formPermission] = Form.useForm();

  const [loading, setLoading] = useState(true)
  const [toggleState, setToggleState] = useState(false)
  const [rowSelected, setRowSelected] = useState(-1)
  const [lastSelected, setLastSelected] = useState(-1)
  const [isInclusao, setIsInclusao] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState(true)

  const [users, setUsers] = useState([])
  const [userBlocked, setUserBlocked] = useState(false)

  const [roles, setRoles] = useState([])
  const [userRoles, setUserRoles] = useState([] as number[])
  const [topSecurityLevelState, setTopSecurityLevelState] = useState(999)

  const [permissions, setPermissions] = useState([])
  const [groupsPermissions, setGroupsPermissions] = useState([])
  const [userPermissions, setUserPermissions] = useState([] as number[])
  const [rolesPermissions, setRolesPermissions] = useState([] as number[])

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerRoleVisible, setDrawerRoleVisible] = useState(false)
  const [drawerPermissionVisible, setDrawerPermissionVisible] = useState(false)
  const [showInputs, setShowInputs] = useState(false)
  const [mensagem, setMensagem] = useState('')
  // const [origem, setOrigem] = useState('')

  const [render, setRender] = useState(0)

  useEffect(() => {
    setLoading(true)
    setTimeout(async function () {
      try {
        await loadUsers()
        await loadRoles() 
        await loadPermissions()  
      } catch (err) {
        // if (customMessage(err)===403) Logout()
      }
      setLoading(false)
    }, 500);
  }, [toggleState])

  async function loadUsers() {
    const response = await apierp.get('usersbt')
    // console.log('users', response.data)
    setLastSelected(-1)
    setUsers(response.data)
  }

  async function loadRoles() {
    if (roles.length > 0) return
    const response = await apierp.get('rolesbt')
    const data = response.data as any;
    // console.log('roles', data)
    setRoles(data);
  }

  async function loadPermissions() {
    if (permissions.length > 0) return
    const response = await apierp.get('permissions')
    const data = response.data as any;
    // console.log('permissions', data)
    const grupos = await groupBy(data, ['group'], ['id'])
    // console.log('grupos', grupos)
    setPermissions(data);
    setGroupsPermissions(grupos)
  }

  async function loadUser(id: number) {
    // console.log('loadUser', typeof id, id)
    await apierp.get(`usersxd/${id}`).then((response) => {
      const data = response.data as any;
      // console.log('usersxd/id', data)
      const userRoles: [] = data.roles.map((x: any) => x.id)
      setUserRoles(userRoles);
      // const sls: [] = data.roles.map((x: any) => x.securityLevel)
      // const tsl =  Math.min.apply(Math, sls);
      // setTopSecurityLevelState(tsl)
      const userPermissions: [] = data.permissions
      const rolesPermissions: [] = data.rolesPermissions
      setUserPermissions(userPermissions);
      setRolesPermissions(rolesPermissions);
    }, (err) => {
      console.log('deu-erro')
      if (customMessage(err) === 403) Logout()
    })
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      ellipsis: true,
      width: '50px',
      className: 'text-right',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
    },
    {
      title: '',
      dataIndex: 'isOwner',
      // ellipsis: true,
      width: '20px',
      className: 'text-center',
      // onCell: (record: any, i: any) => ({ className: lastSelected === i ? "lastSelected" : "" })
      render(text: any, record: any, i: any) {
        return {
          children: (<div > {text ? <div className="flex items-center justify-center"><AndroidOutlined style={{ fontSize: '20px', color: '#08c' }} /></div> : <div className="flex items-center"></div>} </div>)
        }
      }
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      width: '200px',
      className: 'text-left',
      ellipsis: true,
      // render(text: any, record: any, i: any) {
      //   return {
      //     children: (<div> {text} {record.isOwner ? ' (\#)' : ''}</div>)
      //   }
      // }
    },
    {
      title: 'ShortNome',
      dataIndex: 'shortName',
      width: '130px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'TSL',
      dataIndex: 'topSecurityLevel',
      width: '40px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '230px',
      className: 'text-left',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'isInactive',
      width: '90px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.isInactive ? "text-red-500" : "text-blue-500" }),
      render(text: any, record: any, i: any) {
        return {
          children: (<div > {text ? <div className="flex items-center"><LockFilled /><span className="ml-1">Inativo</span></div> : <div className="flex items-center"><CheckSquareFilled /><span className="ml-1">Ativo</span></div>} </div>)
        }
      }
    },
    {
      title: 'No App',
      dataIndex: 'isActiveInTenant',
      width: '90px',
      className: 'text-left',
      ellipsis: true,
      onCell: (record: any, i: any) => ({ className: record.isActiveInTenant ? "text-blue-500" : "text-red-500" }),
      render(text: any, record: any, i: any) {
        return {
          children: (<div > {text ? <div className="flex items-center"><CheckSquareFilled /><span className="ml-1">Ativo</span></div> : <div className="flex items-center"><LockFilled /><span className="ml-1">Inativo</span></div>} </div>)
        }
      }
    },
  ]

  const fillForm = (usert: any) => {
    form.setFieldsValue({ ...usert });
  };

  async function handleRow(record: any, index: any) {
    // console.log('handleRow', record)
    setLastSelected(index)
    // const isBlocked = usuario.id === record.id || record.isOwner
    if (rowSelected === index) {
      setRowSelected(-1)
      // setUserBlocked(true)
    } else {
      setRowSelected(index)
      // setUserBlocked(isBlocked)
      setIsInclusao(false)
      setMensagem('Alteração de Usuário')
      setShowInputs(true)
      // setOrigem('app')
      setTopSecurityLevelState(record.topSecurityLevel)
      showDrawer()
      const usert: any = {
        id: record.id,
        name: record.name,
        shortName: record.shortName,
        email: record.email,
        password: '',
        isInactive: record.isInactive,
        isActiveInTenant: record.isActiveInTenant,
      }
      fillForm(usert)
      // console.log(usert)
      await loadUser(record.id)
    }

  } // fim handleRow

  const initialValues: any = {
    id: '',
    name: '',
    shortName: '',
    email: '',
    password: '',
    isInactive: false,
    isActiveInTenant: true,
  }

  function novoUser() {
    // form.resetFields(); // NÃO FUNCIONOU AQUI
    setIsInclusao(true)
    setUserBlocked(true)
    setMensagem('')
    setShowInputs(false)
    // setOrigem('')
    form.setFieldsValue(initialValues)
    showDrawer()
  }

  function resetForm() {
    form.setFieldsValue(initialValues)
    setRowSelected(-1)
    // novoUser()
    setIsInclusao(true)
    setUserBlocked(true)
    setMensagem('')
    setShowInputs(false)
    // setOrigem('')
    // setToggleState(!toggleState)
  };

  const showDrawer = () => {
    setButtonsDisabled(false)
    setDrawerVisible(true)
  };

  const showDrawerRole = () => {
    setButtonsDisabled(false)
    setDrawerRoleVisible(true)
  };

  const showDrawerPermission = () => {
    setButtonsDisabled(false)
    setDrawerPermissionVisible(true)
  };

  const hideDrawer = () => {
    setButtonsDisabled(false)
    setRowSelected(-1)
    // setUser(null)
    setDrawerVisible(false)
  };

  const hideDrawerRole = () => {
    setButtonsDisabled(false)
    setDrawerRoleVisible(false)
  };

  const hideDrawerPermission = () => {
    setButtonsDisabled(false)
    setDrawerPermissionVisible(false)
  };

  function enableButtons() {
    // console.log('enableButtons')
    setButtonsDisabled(false)
    setUserBlocked(false)
    // setRender(render + 1)
  }

  async function handleSubmit(dados: any) {
    //  console.log(origem,dados) // auaa auia iuia
    setButtonsDisabled(true)
    dados.name = dados.name.trim().replace(/\s\s+/g, ' ')
    dados.shortName = dados.shortName.trim().replace(/\s\s+/g, ' ')
    if (dados.id === '') {
      //CREATE // iuia
      try {
      const result = await apierp.post(`users/app`, dados);
        setToggleState(!toggleState)
        message.success('Usuário incluido com sucesso!');
        hideDrawer()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    } else {
      //UPDATE // auaa  auia
      try {
        const result = await apierp.put(`users/app/${dados.id}`, dados);
        setToggleState(!toggleState)
        message.success('Usuário alterado com sucesso!');
        hideDrawer()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
    }
  }

  async function handleDelete() {
    const dados = form.getFieldsValue()
    try {
      await apierp.delete(`users/${dados.id}`);
      setToggleState(!toggleState)
      setLastSelected(-1)
      message.success('Usuário excluido com sucesso!');
      hideDrawer()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
    }
  }

  function confirmResetSenha(e: any) {
    handleResetPassword();
    hideDrawer()
  }

  function cancelResetSenha(e: any) {
    hideDrawer()
  }

  function confirmDelete(e: any) {
    handleDelete();
  }

  function cancelDelete(e: any) {
    // message.error('User não foi excluído');
    hideDrawer()
  }

  function resetFormPartial() {
    form.setFieldsValue({ id: '' })
    form.setFieldsValue({ name: '' })
    form.setFieldsValue({ shortName: '' })
    form.setFieldsValue({ password: '' })
    form.setFieldsValue({ isInactive: false })
    form.setFieldsValue({ isActiveInTenant: true })
  }

  async function handleSubmitRole() {
    setButtonsDisabled(true)
    const userSelected: any=users[rowSelected]
    const result = await apierp.post(`usersacl/${userSelected.id}`, {roles: userRoles})
    setToggleState(!toggleState); // <== NECESSÁRIO APENAS PARA GARANTIR A RENDERIZAÇÃO DOS DRAWERS
    loadUser(userSelected.id)
    // console.log(userSelected.id, userRoles)
    message.success('Perfil do Usuário atualizado com sucesso!');
    hideDrawerRole()
  }

  function onCheckRole(e: any, id: number) {
    const novo = userRoles.map((x: any) => +x)
    
    if (e.target.checked) {
      // adiciona id em userRoles
      novo.push(id)
      setUserRoles(novo)
    } else {
      //remove id de userRoles
      const index = userRoles.indexOf(id);
      if (index > -1) {
        novo.splice(index, 1); // 2nd parameter means remove one item only
        setUserRoles(novo)
      }
    }
  }

  async function handleSubmitPermission() {
    setButtonsDisabled(true)
    const userSelected: any=users[rowSelected]
    const result = await apierp.post(`usersacl/${userSelected.id}`, {permissions: userPermissions})
    message.success('Permissões do Usuário atualizado com sucesso!');
    hideDrawerPermission()
  }
  async function handleResetPassword() {
    const userSelected: any=users[rowSelected]
    const dados = {
        id: userSelected.id,
        newpassword: userSelected.email.split('@')[0],
        mustChangePassword:true
    }
    setButtonsDisabled(true)
    const result = await apierp.put(`changepwd`, dados)
    message.success('Senha resetada com sucesso!');
    hideDrawerPermission()
  }

  function onCheckPermission(e: any, id: number) {
    const novo = userPermissions.map((x: any) => +x)
    
    if (e.target.checked) {
      // adiciona id em menusRoles
      novo.push(id)
      setUserPermissions(novo)
    } else {
      //remove id de menusRoles
      const index = userPermissions.indexOf(id);
      if (index > -1) {
        novo.splice(index, 1); // 2nd parameter means remove one item only
        setUserPermissions(novo)
      }
    }
  }

  async function handleEmail() {
    const email = form.getFieldValue('email')
    resetFormPartial()
    // form.setFieldsValue({ email })
    setUserBlocked(false)
    const response = await apierp.get(`userbyemail/${email}`)
    const user = response.data
    // console.log('email enter', email, user)
    if (user.id) {
      setIsInclusao(false)
      const thisTenant = user.usersTenants.find((x: any) => x.tenantId === userTenant.tenantId)
      // console.log('thisTenant', thisTenant)
      form.setFieldsValue({ id: user.id })
      form.setFieldsValue({ name: user.name })
      form.setFieldsValue({ shortName: user.shortName })
      form.setFieldsValue({ password: '' })
      form.setFieldsValue({ isInactive: user.isInactive })
      form.setFieldsValue({ isActiveInTenant: thisTenant ? thisTenant.isActiveInTenant : true })
      // setToggleState(!toggleState)
      // setShowInputs(false)
      // if thisTenant existe, mensagem "usuario já existe" 
      if (thisTenant) {
        setMensagem('Alteração de Usuário')
        setShowInputs(true)
        // setOrigem('app')
      } else {
        setMensagem('Alteração de Usuário e Inclusão no App')
        setShowInputs(true)
        // setOrigem('app')
      }
    } else {
      // console.log('não tem user - incluir e adicionar ao app')
      setMensagem('Inclusão de Usuário e Inclusão no App')
      setShowInputs(true)
      // setOrigem('app')
      setIsInclusao(true)
    }
  }
  // function Logout() {
  //   alert('Implementar useAuth')
  // }

  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => printDiv('printableDiv-user', 'user')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
        <Button onClick={() => copyToClipboard('user')} shape="round" size="small" icon={<CopyOutlined />} title="Copy to Clipboard"></Button>
        <Button onClick={() => downloadToCsv('user', "Users.csv")} shape="round" size="small" icon={<DownloadOutlined />} title="Download CSV"></Button>
        <Button onClick={() => novoUser()} type="primary" shape="round" size="small" icon={<PlusOutlined />} title="Novo"></Button>
      </Space>
    )
  }

  const wTab = 780 + 90
  // const htb = `calc(100vh - 149px - 100px)`
  const htb = `calc(100vh - 149px - 110px)`
  const hsc = `calc(100vh - 180px - 109px)` // 1 a menos que htb

  // console.log('render', render, buttonsDisabled)
  // console.log(topSecurityLevel, usuario.topSecurityLevel)

  return (
    <Layout titulo={menu.label} botoes={botoes()}>
      <div className="spinner mt-5 justify-center items-center " style={{ display: loading ? "flex" : "none" }} >
        <Spinner />
      </div>
      <div className="bg-blue-30 mx-5 overflow-x-auto">
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
          <div id="printableDiv-user" className="pt-6  pb-[1px] fadein" style={{ display: loading ? "none" : "" }}>
            <h1 className='hidden mb-1 text-2xl font-bold'>Usuários</h1>
            {/* TABLE */}
            <div className="flex flex-col justify-between bg-[#f8f9fb]" style={{ maxHeight: htb }}> {/** ou heigth */}
              <div className="envelopetable ">
                <Table id='user' dataSource={users} columns={columns} size="small" rowKey="id"
                  rowClassName={(record, index) => (index === rowSelected ? "rowSelected" : "")}
                  pagination={false}
                  scroll={{ y: hsc }}
                  onRow={(record: any, rowIndex: any) => {
                    return {
                      onClick: event => { handleRow(record, rowIndex) }, // click row
                      className: lastSelected === rowIndex ? "lastSelected" : ""
                    };
                  }}
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* DRAWER FORM */}
      <Drawer
        title={(isInclusao ? "Novo" : "Alterar") + " Usuário"}
        width={360}
        closable={false}
        maskClosable={false}
        onClose={hideDrawer}
        visible={drawerVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={initialValues}>
            <h1 className='mb-3 text-xl font-bold h-[28px]'>{form.getFieldValue('name')} </h1>
            <Form.Item name="id" hidden noStyle></Form.Item>
            
            {isInclusao ?
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Entre com Email do Usuário' }]}
                  >
                    <Search placeholder="Email do usuário" onChange={() => enableButtons()} onPressEnter={() => handleEmail()} onSearch={() => handleEmail()} />
                  </Form.Item>
                </Col>
              </Row>
              :
              <>
                <Form.Item name="email" hidden noStyle></Form.Item>
                <div className="mb-2">{form.getFieldValue('email')}</div>
              </>
            }

            <div className={(showInputs ? "block" : "hidden") }>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, message: 'Entre com Nome do usuário' }]}
                >
                  <Input placeholder="Nome do usuário" onChange={() => enableButtons()} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="shortName"
                  label="ShortNome"
                  rules={[{ required: true, message: 'Entre com o Nome curto do usuário' }]}
                >
                  <Input placeholder="Nome curto do usuário" onChange={() => enableButtons()} />
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={16}>
              <Col span={24}>
              <Form.Item className='xmt-4'
                name="password"
                label="Senha:"
              >
                <Input placeholder="Senha" />
              </Form.Item>
              </Col>
            </Row> */}
            {/* <Form.Item name="password" hidden noStyle></Form.Item> */}

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="isInactive"
                  label="Status Geral"
                >
                  <Radio.Group>
                    <Radio value={true} disabled onChange={() => enableButtons()}>Inativo</Radio>
                    <Radio value={false} disabled onChange={() => enableButtons()}>Ativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="isActiveInTenant"
                  label="Status Neste App"
                >
                  <Radio.Group >
                    <Radio value={false} disabled={topSecurityLevelState <= usuario.topSecurityLevel} onChange={() => enableButtons()}>Inativo</Radio>
                    <Radio value={true} disabled={topSecurityLevelState <= usuario.topSecurityLevel} onChange={() => enableButtons()}>Ativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className="mb-1">Operações a realizar:</div>
            <div className="bg-amber-200 px-2 py-1 font-semibold">{mensagem}</div>
            <Space className="inner-drawer-buttons">
              <Button className="!bg-gray-100" onClick={showDrawerRole} disabled={isInclusao}>Perfis</Button>
              <Button className="!bg-gray-100" onClick={showDrawerPermission} disabled={isInclusao}>Permissões</Button>
            </Space>
            </div>
          </Form>
        </div>
        <Space className="drawer-footer flex w-full justify-end">
          <Space>
            <Button onClick={hideDrawer} type="primary" icon={<FastBackwardOutlined />} />
            {/* <Button onClick={hideDrawer} >Cancel</Button> */}
            {/* <Button onClick={resetForm}>Reset</Button> */}
            
            
            <Popconfirm disabled={userBlocked}
              title="Deseja realmente resetar a senha?"
              onConfirm={confirmResetSenha}
              onCancel={cancelResetSenha}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => { }} type="primary" danger disabled={isInclusao || userBlocked || buttonsDisabled} >Reset Senha</Button>
              {/* <Button onClick={handleResetPassword}>Reset Senha</Button> */}
            </Popconfirm>

            <Popconfirm disabled={userBlocked}
              title="Deseja realmente excluir este usuário?"
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
              okText="Sim"
              cancelText="Não"
            >
              <Button onClick={() => { }} type="primary" danger disabled={ isInclusao || userBlocked || buttonsDisabled} >Delete</Button>
            </Popconfirm>

            <Button onClick={form.submit} disabled={userBlocked || buttonsDisabled} type="primary">Submit</Button>
          </Space>
        </Space>

        <Drawer
            title="Perfis"
            width={360}
            closable={false}
            onClose={hideDrawerRole}
            visible={drawerRoleVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
          >
            <div className="inner-drawer-body" >
              <Form layout="vertical" form={formRole} onFinish={handleSubmitRole} >
                <Title level={5}>{form.getFieldValue('name')}</Title>
                {roles.map((role: any) => {
                  const isChecked = userRoles.findIndex((x) => x === role.id) >= 0
                  const icon = <Checkbox checked={isChecked} disabled={role.securityLevel <= usuario.topSecurityLevel} onChange={(e) => onCheckRole(e, role.id)} />
                  return (
                    <>
                      <div key={role.id} id={role.id}  className={role.securityLevel < usuario.topSecurityLevel ? "hidden" : "flex" } >
                        <div className="w-4">{icon}</div>
                        <div className={"pl-2"}>{role.name}</div>
                      </div>
                    </>
                  )
                })}
              </Form>
            </div>
            <Space className="drawer-footer flex w-full justify-end">
              <Space>
                <Button onClick={hideDrawerRole}>Cancel</Button>
                <Button onClick={formRole.submit} disabled={buttonsDisabled} type="primary">Submit</Button>
              </Space>
            </Space>
        </Drawer>
        
        <Drawer
            title="Permissões"
            width={360}
            closable={false}
            onClose={hideDrawerPermission}
            visible={drawerPermissionVisible}
            headerStyle={{ borderRadius: "0" }}
            bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
          >
          <div className="inner-drawer-body">
            <Form layout="vertical" form={formPermission} onFinish={handleSubmitPermission} >
              <Title level={5}>{form.getFieldValue('name')}</Title>
              {groupsPermissions.map((gr: any) => {
                return (
                  <>
                    <div key={gr.group} id={gr.group} data-pai={null} className={"n0 text-pink-500"} >
                      <div className="">{gr.group} </div>
                    </div>
                    {permissions.filter((x: any) => x.group === gr.group).map((perm: any) => {
                    const isChecked = userPermissions.findIndex((x) => x === perm.id) >= 0
                    const isStarred = rolesPermissions.findIndex((x) => x === perm.id) >= 0

                    const icon = <Checkbox checked={isChecked} disabled={perm.securityLevel <= usuario.topSecurityLevel} onChange={(e) => onCheckPermission(e, perm.id)} />
                    const icon2 =  isStarred ? <StarFilled style={{ color: '#1890ff', fontSize: '16px', margin: '4px 0 0 0'}} /> : <StarOutlined style={{ color: 'var(--gray-100)', fontSize: '16px', margin: '4px 0 0 0'}} />
                    return (
                        // OCULTAR
                        <div  key={perm.id} id={perm.id} className={perm.securityLevel < usuario.topSecurityLevel ? "hidden" : "flex" }>
                          <div className="w-7 pl-3">{icon}</div>
                          <div className="w-7 pl-2">{icon2}</div>
                          <div className="">{perm.description}</div>
                        </div>
                      )
                    })
                    }
                  </>
                )
              })
              }
            </Form>
          </div>
          <Space className="drawer-footer flex w-full justify-end">
            <Space>
              <Button onClick={hideDrawerPermission}>Cancel</Button>
              <Button onClick={formPermission.submit} disabled={buttonsDisabled} type="primary">Submit</Button>
            </Space>
          </Space>
        </Drawer>

      </Drawer>
    </Layout>
  );
}