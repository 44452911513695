import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { useGeral } from '../../contexts/geral';
import { useNavigate, useParams } from "react-router-dom";
import Layout from '../../components/template/Layout';
import { apierp } from '../../services/api';
import CardMenu from '../../components/template/CardMenu';
import CardMenuNarrow from '../../components/template/CardMenuNarrow';
import useWindowSize from '../../hooks/useWindowSize';

export default function Submenu(props: any) {
    const {menuid} = useParams();
  const { user, Logout } = useAuth();
  const usuario: any = user
  // console.log(usuario)
  const { userTenant, rotaBase, userMenu, setMenuSelected, setMenuGrupoSelected } = useGeral();
  const [innerW, innerH] = useWindowSize();
  
  const menu = userMenu.find((x: any) => x.id == menuid)
//   const submenu = userMenu.filter((x: any) => x.parentId == menuid)
//   console.log('userMenu', menuid ,menu, submenu)
//   const [itemsMenu, setItemsMenu] = useState([])
  let navigate = useNavigate();
  
  useEffect(() => {
    if (!usuario?.shortName) {
      navigate(`/login`);
    }

    // setShowMenu(false)
    // setMenuGrupoSelected(-1)
    // const [itemsMenu] = useState(usuario?.userMenu)

  }, [])

  const clickMenu = (menuId: number, rota: string) => {
    // console.log('clickUser', i, typeof i)
    setMenuSelected(menuId)
    // if (innerW < 640 && innerW > 0) setShowMenu(false)
    navigate(rota);
  }

  return (
    <Layout
      titulo={menu?.label}
    >
    <div className='flex flex-wrap items-center justify-center w-250 min-w-[360px] overflow-x-hidden fadein'>
        { 
            userMenu.filter((x: any) => x.parentId == menuid).map((item: any, i: number) => {
              // return (<><div>{item.label}{item.url}</div><br /></>)
              const menuId=item.id
              const rota = item.url ? `${rotaBase}${item.url}` : `${rotaBase}/${item.id}`
              const bgcard = item.url ? 'bg-zinc-100' : 'bg-violet-600'
              const txtcard = item.url ? 'text-violet-900' : 'text-white' // 'text-white'
              return (
                <div key={item.id}  onClick={() => clickMenu(menuId, rota)}>
                  {innerW > 840 
                  ? <CardMenu bg={bgcard} txt={txtcard} title={item.label} description="" imagem="images/gota.png"/>
                  : <CardMenuNarrow bg={bgcard} txt={txtcard} title={item.label} description="" imagem="images/gota.png"/>
                  }
                </div>
              )
            })
        }
      </div>
    </Layout>
    
  )
}
