import { Button, Radio, RadioChangeEvent, Select, Space } from "antd";
import Layout from "../../../components/template/Layout";
import { useDadosMapa } from "./useDadosMapa";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { PlusOutlined } from '@ant-design/icons';

import { CardProduto } from "../../__Shared/Produtos/CardProduto";
import Spinner from "../../../components/template/Spinner";
import DrawerFormProduto from "./DrawerFormProduto";
import isPermitted from "../../../hooks/isPermitted";

const { Option } = Select;

export default function MapaProduto() {

  const permiteIncluir = isPermitted('incluir_produtos')
  const permiteAlterar = isPermitted('alterar_produtos')

  const [produtoId, setProdutoId] = useState(null as any)
  const [codigo, setCodigo] = useState(null as any)
  const [ordem, setOrdem] = useState('N' as any) // C = Codigo, N = Nome
  const [mostrar, setMostrar] = useState('T' as any) // A = Ativos, I = Inativos, T = Todos

  const { qrEmpresas, qrProdutos, qrProduto, qrBlingProdutos, qrProdutosBling } = useDadosMapa(produtoId, codigo);
  const empresas = qrEmpresas.data
  const produto = qrProduto.data
  // const produtos = qrProdutos.data
  const blingProdutos: any = [null,null] // qrBlingProdutos.data || []
  const bp0 = qrBlingProdutos.data?.find((c: any) => c.empresaId === 1)
  const bp1 = qrBlingProdutos.data?.find((c: any) => c.empresaId === 2)
  if (bp0) blingProdutos[0] = bp0
  if (bp1) blingProdutos[1] = bp1
  // console.log('blingProdutos', blingProdutos)

  const produtosBling = [null,null] // qrProdutosBling.data || []
  const pb0 = qrProdutosBling.data?.find((c: any) => c.empresaId === 1)
  const pb1 = qrProdutosBling.data?.find((c: any) => c.empresaId === 2)
  if (pb0) produtosBling[0] = pb0
  if (pb1) produtosBling[1] = pb1
  // console.log('produtosBling', produtosBling)

  const nomeEmpresa1 = empresas?.find((c: any) => c.id === 1)?.nome.replace('Colorbrilho ', '')
  const nomeEmpresa2 = empresas?.find((c: any) => c.id === 2)?.nome.replace('Colorbrilho ', '')
  const idsBling = blingProdutos.map((c: any) => {
    if (c) return { empresaId: c.empresaId, idbling: c.idbling}
    // return { empresaId: c.empresaId, idbling: c.idbling}
  })
  const codigos = qrProdutos?.data?.map((c: any) => c.codigo)

  const prod = (mostrar === 'A') ? qrProdutos?.data?.filter((c: any) => c.situacao[0] === 'A')
    : (mostrar === 'I') ? qrProdutos?.data.filter((c: any) => c.situacao[0] === 'I')
      : qrProdutos?.data
  const produtos = (ordem === 'N')
    ? prod?.sort((a: any, b: any) => a.nome.localeCompare(b.nome))
    : prod?.sort((a: any, b: any) => a.codigo.localeCompare(b.codigo))

  const [drawerFormAlteraProdutoVisible, setDrawerFormAlteraProdutoVisible] = useState(false)
  const [drawerFormProdutoVisible, setDrawerFormProdutoVisible] = useState(false)
  const [operacao, setOperacao] = useState(null)

  const onChange = (value: any) => {
    setProdutoId(value)
    setCodigo(produtos?.find((c: any) => c.id === value)?.codigo)
  }

  const onChangeOrdem = (e: RadioChangeEvent) => {
    setOrdem(e.target.value);
  };
  const onChangeMostrar = (e: RadioChangeEvent) => {
    setMostrar(e.target.value);
  };

  function alterarProduto() {
    // console.log('alteraProduto', produto)
    setDrawerFormAlteraProdutoVisible(true)
  }

  const hideDrawerFormAlteraProduto = () => {
    setDrawerFormAlteraProdutoVisible(false)
  }

  function manterProduto(operacao: any) {
    // console.log('alteraProduto', produto)
    setOperacao(operacao)
    setDrawerFormProdutoVisible(true)
  }

  const hideDrawerFormProduto = () => {
    setDrawerFormProdutoVisible(false)
  }

  // const wTab = 800 // + 8 // liquido = x-8
  // const htb = `calc(100vh - 149px - 110px - 26px)`
  // const hsc = `calc(100vh - 180px - 109px - 26px)` // 1 a menos que       

  const wContent = 2 * (600 + 8) + 4

  return (
    <Layout titulo={"Mapa do Produto"} botoes={null}>
      <div className="select flex mx-auto items-center px-2 border border-solid" >
        <div>
          <Space size={5}>
            <h3 className="pt-[6px] mr-3">Ordem:</h3>
            <Radio.Group className="font-semibold" onChange={onChangeOrdem} value={ordem}>
              <Radio value={'C'}>Codigo</Radio>
              <Radio value={'N'}>Nome</Radio>
            </Radio.Group>
          </Space>
          <Select placeholder="Digite parte do nome do produto ..."
            showSearch
            style={{ width: '640px' }}
            optionFilterProp="children"
            onChange={onChange}
            popupClassName="border-solid border border-blue-500">
            {/* <Option value={null}>É Nivel Raiz</Option> */}
            {produtos?.map((produto: any, index: any) => {
              // const icon = produto?.situacao[0] === 'A' ? <FaEye className="text-green-500" /> : <FaEyeSlash className="text-red-500" />
              const cor = produto.situacao[0] === 'A' ? 'text-blue-800' : 'text-red-600'
              const bgcor = produto.situacao[0] === 'A' ? '' : 'bg-red-200'
              return <Option value={produto.id} className={`flex items-center font-semibold ${cor}`} >{produto.codigo} - {produto.nome}</Option>
            })}
          </Select>
          <Space size={5}>
            <h3 className="pt-[6px] ml-3">Mostrar:</h3>
            <Radio.Group className="font-semibold" onChange={onChangeMostrar} value={mostrar}>
              <Radio value={'A'}>{/*<FaEye className="text-green-500" />*/}Ativos</Radio>
              <Radio value={'I'}>{/*<FaEyeSlash className="text-red-500" />*/}Inativos</Radio>
              <Radio value={'T'}>Todos</Radio>
            </Radio.Group>
          </Space>

        </div>
      </div>

      <Space className="mx-auto mt-3" size={10}>
        <Button hidden={!produtoId || !permiteAlterar} onClick={() => { manterProduto('Alteração') }} type="primary" className="bg-amber-600" shape="round" size="small">Alterar este Produto</Button>
        <Button hidden={!permiteIncluir} onClick={() => { manterProduto('Inclusão') }} type="primary" className="bg-indigo-600" shape="round" size="small">Criar Novo Produto</Button>
        <Button hidden={!produtoId || !permiteIncluir} onClick={() => { manterProduto('Clonagem') }} type="primary" className="bg-green-600" shape="round" size="small">Clonar este Produto</Button>
      </Space>

        <div hidden={!produtoId} className="fonte w-full p-1 mt-3 font-semibold bg-white text-center">ZAPP</div>
        <div hidden={!produto || qrProduto.isLoading || qrProduto.isRefetching} className="produtoZap overflow-x-auto mx-auto border border-solid">
          <CardProduto produto={produto} wCard={600} className='bg-slate-1000' debug={'P'} formato={0} alterarProduto={alterarProduto} />
        </div>

      <Spinner loading={qrBlingProdutos.isLoading || qrBlingProdutos.isRefetching}>Carregando!</Spinner>
      <div hidden={!produtoId || qrBlingProdutos.isLoading || qrBlingProdutos.isRefetching} className={`flex gap-0 w-[${wContent}px] mx-auto mt-2`}>
        <div className={`colpa p-[2px]  mx-1 `}>
          <div className="fonte w-full p-1 mt-1 font-semibold bg-white text-center">BLING-ZAPP {nomeEmpresa1}</div>
          <div hidden={!produto} className="produtoZap overflow-x-auto mx-auto border border-solid">
            <CardProduto produto={blingProdutos[0]} wCard={600} className='bg-slate-1000' debug={'PZ1'} formato={1} />
          </div>
        </div>
        <div className={`colpa p-[2px]  mx-1 `}>
          <div className="fonte w-full p-1 mt-1 font-semibold bg-white text-center">BLING-ZAPP {nomeEmpresa2}</div>
          <div hidden={!produto} className="produtoZap overflow-x-auto mx-auto border border-solid">
            <CardProduto produto={blingProdutos[1]} wCard={600} className='bg-slate-1000' debug={'PZ2'} formato={1} />
          </div>
        </div>
      </div>

      <Spinner loading={qrProdutosBling.isLoading || qrProdutosBling.isRefetching}>Consultando Bling</Spinner>
      <div hidden={!produtoId || qrProdutosBling.isLoading || qrProdutosBling.isRefetching} className={`flex gap-0 w-[${wContent}px] mx-auto my-3`}>
        <div className={`colpa p-[2px]  mx-1 `}>
          <div className="fonte w-full p-1 mt-1 font-semibold bg-white text-center">BLING {nomeEmpresa1}</div>
          <div hidden={!produto} className="produtoZap overflow-x-auto mx-auto border border-solid">
            <CardProduto produto={produtosBling[0]} wCard={600} className='bg-slate-1000' debug={'PB1'} formato={1} />
          </div>
        </div>
        <div className={`colpa p-[2px]  mx-1 `}>
          <div className="fonte w-full p-1 mt-1 font-semibold bg-white text-center">BLING {nomeEmpresa2}</div>
          <div hidden={!produto} className="produtoZap overflow-x-auto mx-auto border border-solid">
            <CardProduto produto={produtosBling[1]} wCard={600} className='bg-slate-1000' debug={'PB2'} formato={1} />
          </div>
        </div>
      </div>

      {/* <DrawerFormAlteraProduto hideDrawerFormAlteraProduto={hideDrawerFormAlteraProduto} drawerFormAlteraProdutoVisible={drawerFormAlteraProdutoVisible} produto={produto} /> */}
      <DrawerFormProduto hideDrawerFormProduto={hideDrawerFormProduto} drawerFormProdutoVisible={drawerFormProdutoVisible} produto={produto} operacao={operacao} idsBling={idsBling} codigos={codigos} />

    </Layout>
  )
}