import Layout from "../../../components/template/Layout";
import usePedidosPendentesSaidaByDate from "./usePedidosPendentesSaidaByDate";
import CardPedidosPendentes from "./CardPedidosPendentes";
import { Button, Col, Divider, Drawer, Form, Input, InputNumber, Modal, Row, Space, Typography, message } from "antd";
import { DownloadOutlined, FastBackwardOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import useWindowSize from "../../../hooks/useWindowSize";
import { useAuth } from "../../../contexts/auth";
import { dateFormat, dateMySqlToPtBr } from "../../../utils/dateFunctions";
import useProcessando from "../../../data/hooks/useProcessando";
import { apierp } from "../../../services/api";
import { customMessage } from "../../../components/template/CustomMessage";
import { useNavigate } from "react-router-dom";

const { Title, Text, Paragraph } = Typography;

// import { useAuth } from '../../contexts/auth';
// import history from '../../services/history'
// import { useHistory } from "react-router-dom";

// import { Center, Heading, SideMenu, VStack } from "../../components"


export default function SaidaMercadoriasByDate() {
  const [mesref, setMesRef] = useState(null as any)

  const { pedidosPendentesSaida, empresas, caixas, processando, reload } = usePedidosPendentesSaidaByDate(mesref)

  const now = new Date()
  useEffect(() => {
    // console.log('useEffect unico')
    setMesRef(now)
  }, [])

  // console.log(pedidosPendentesSaida)
  // console.log(caixas)
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const [drawerFormPrepararEntregaVisible, setDrawerFormPrepararEntregaVisible] = useState(false)
  const [rowPedidoSelected, setRowPedidoSelected] = useState(-1)

  const [formSaidaMercadoria] = Form.useForm();
  const [deliveryDate, setDeliveryDate] = useState(new Date(dateFormat(new Date(), 'yyyy-MM-dd 12:00:00')));

  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [toggleState, setToggleState] = useState(false)

  let navigate = useNavigate();

  // Drawers Form prepararEntrega
  const showDrawerFormPrepararEntrega = () => {
    setButtonsDisabled(false)
    setDrawerFormPrepararEntregaVisible(true)
  };
  const hideDrawerFormPrepararEntrega = async () => {
    setButtonsDisabled(false)
    setDrawerFormPrepararEntregaVisible(false)
  };

  async function handleRow(id: any, index: any) {
    formSaidaMercadoria.resetFields()
    // formSaidaMercadoria.setFieldsValue({'dataSaidaEstoque': ''})
    const pedidoPendenteSaida = pedidosPendentesSaida[index]
    // console.log(id, index, pedidoPendenteSaida)
    // console.log(caixas)
    setRowPedidoSelected(index)
    // console.log(dateFormat(Date.now(), 'yyyy-MM-dd HH:mm:ss'))
    // console.log(new Date(dateFormat(Date.now(), 'yyyy-MM-dd HH:mm:ss')))
    const pedidoPendente = {
      id,
      numero: pedidoPendenteSaida.numero,
      data: pedidoPendenteSaida.data,
      // dataSaidaEstoque: new Date(dateFormat(Date.now(), 'yyyy-MM-dd')),
      dataSaidaEstoque: new Date(),
      cliente: pedidoPendenteSaida.cliente.nome,
      itens: pedidoPendenteSaida.itens,
      caixas: caixas.map((it: any) => {
        return ({ id: it.id, descricao: it.descricao, nomeComponente: it.nomeComponente })
      })
    }

    formSaidaMercadoria.setFieldsValue(pedidoPendente)

    showDrawerFormPrepararEntrega()
  }
  const handleChangeDate = (newDate: any) => {
    // console.log(newDate, typeof newDate)
    setToggleState(!toggleState)
  }
  const dateChange = (date: any) => {
    setDeliveryDate(date)
    // setHideButtonFinaliza(true)
  }

  function enableButtons(wait: number) {
    setTimeout(function () {
      setButtonsDisabled(false)
      // setMsgError('')
    }, wait);
  }
  function disableButtons() {
    setButtonsDisabled(true)
  }

  async function handleSubmitSaidaMercadoria(dadosForm: any) {
    disableButtons()
    const dados: any = {
      pedidoVendaId: dadosForm.id,
      // dataSaidaEstoque: dateFormat(dadosForm.dataSaidaEstoque,'yyyy-MM-dd'),
      dataSaidaEstoque: dateFormat(deliveryDate, 'yyyy-MM-dd'),
      caixas: []
    }
    delete dadosForm.id
    delete dadosForm.dataSaidaEstoque
    const entries = Object.entries(dadosForm);
    const caixas = []
    for (let it of entries) {
      if (it[1]) {
        caixas.push({
          produtoId: +it[0],
          quantidade: +it[1]
        })
      }
    }
    dados.caixas = caixas
    // console.log(dados)
    //UPDATE
    try {
      const result = await apierp.put(`blingpedidosvendav2/${dados.pedidoVendaId}`, dados);
      setToggleState(!toggleState)
      message.success('Saida de Mercadoria registrada com sucesso!');
      hideDrawerFormPrepararEntrega()
    } catch (err) {
      if (customMessage(err) === 403) Logout()
      enableButtons(500)

    }
    setRowPedidoSelected(-1)
    reload()
    hideDrawerFormPrepararEntrega()
  }

  async function importarPedidosVenda() {
    const rota = "/zapp/" + usuario.shortName.replaceAll(' ', '_') // + "_" + userTenant.tenant.id
    navigate(`${rota}/blingpedidosvendav2`)

    // apierp.get(`blingpedidosvendav2`);
    // Modal.success({
    //   title: 'Sucesso', content: (
    //     <>
    //       <p>Sua solicitação foi enviada ao servidor!</p>
    //       <p>Se existirem novos pedidos, eles estarão disponiveis dentro de alguns minutos</p>
    //       <p className="text-red-500 font-semibold">Por favor, AGUARDE alguns minutos e clique na tecla F5 para atualizar esta tela!</p>
    //     </>
    //   )
    // });
  }


  const botoes = () => {
    return (
      <Space size={10}>
        <Button onClick={() => { importarPedidosVenda() }} type="primary" shape="round" size="small" icon={<DownloadOutlined />} hidden={!true} title="Buscar pedidos">Buscar Pedidos</Button>
      </Space>
    )
  }
  const wTab = 740 // liquido = x-8
  const htb = `calc(100vh - 149px - 110px)`
  const wDrawer = innerW > 640 ? innerW - 144 : 360 // - 144
  const wDrawerx = innerW > 720 && innerW > 360 ? 720 : 360 // - 144

  // const formItemLayout = {
  //   labelCol: { span: 16 },
  //   wrapperCol: { span: 8 },
  // };
  const formTailLayout = {
    labelCol: { span: 16 },
    wrapperCol: { span: 8, offset: 16 },
  };

  let corPicker = 'bg-blue-600'
  if (mesref !== null) {
    corPicker = dateFormat(mesref, 'yyyy-MM-dd') === dateFormat(now, 'yyyy-MM-dd') ? 'bg-blue-600' : 'bg-red-600'
  }
  return (
    <Layout titulo={'Saída de Mercadorias'} botoes={botoes()}>
      <div className="mx-auto">
        <DatePicker
          // wrapperClassName="btmonth"
          className={`w-[88px] mb-[3px] text-center uppercase border-none rounded-md font-medium py-1 cursor-pointer text-white ${corPicker}`}
          selected={mesref}
          locale="pt-BR"
          onChange={(date: any) => setMesRef(date)}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          todayButton="Hoje"
          popperPlacement="bottom"
        />
      </div>
      <div className="overflow-x-auto">
        <div className="mx-auto" style={{ minWidth: wTab, maxWidth: wTab }}>
          <div className="flex flex-col items-center mb-4" style={{ minHeight: htb, maxHeight: htb }}>
            {pedidosPendentesSaida.map((pedido: any, index) =>
              <CardPedidosPendentes className="" key={index} pedido={pedido} empresas={empresas} rowIndex={index} rowSelected={rowPedidoSelected} handleRow={handleRow} />
            )}
          </div>
        </div>
      </div>

      {/* DRAWER FORM PREPARAR ENTREGA */}
      <Drawer
        title={'Preparar Entrega'}
        width={wDrawerx}
        closable={true}
        // maskClosable={false}
        onClose={hideDrawerFormPrepararEntrega}
        open={drawerFormPrepararEntregaVisible}
        headerStyle={{ borderRadius: "0" }}
        bodyStyle={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <div className="inner-drawer-body">
          <Form layout="horizontal" form={formSaidaMercadoria} onFinish={handleSubmitSaidaMercadoria} >
            <Title level={5}>Pedido {formSaidaMercadoria.getFieldValue('numero')} data: {formSaidaMercadoria.getFieldValue('itens') && dateMySqlToPtBr(formSaidaMercadoria.getFieldValue('data'))} - {formSaidaMercadoria.getFieldValue('cliente')}</Title>
            {/* <Title level={5}>Pedido {formSaidaMercadoria.getFieldValue('numero')} data: {formSaidaMercadoria.getFieldValue('data')} - {formSaidaMercadoria.getFieldValue('cliente')}</Title> */}
            <div className="my-2 font-semibold">Itens:</div>
            {formSaidaMercadoria.getFieldValue('itens') && formSaidaMercadoria.getFieldValue('itens').map((item: any, index: number) => {
              return (<div key={index} className="flex justify-between font-medium mr-4"><div>{item.codigo} - {item.descricao}</div> <div>{item.quantidade} {item.unidade}</div> </div>)
            })}
            {/* <div className="my-2 font-semibold">Data de preparação da entrega:</div> */}
            <Form.Item name="id" hidden noStyle></Form.Item>
            {/* <Row className="mt-4" gutter={16}>
              <Col span={12}> */}
            <div className="font-medium">
              <Form.Item className="mt-4 w-[350px]"
                name="dataSaidaEstoque"
                label="Data de preparação da entrega"
                labelCol={{ span: 18 }}
                rules={[{ required: true, message: 'Data Obrigatória' }]}
              >
                {/* <DatePicker className="ant-input" selected={formSaidaMercadoria.getFieldValue('dataSaidaEstoque')} locale="pt-BR" dateFormat="dd/MM/yyyy" onChange={(date: any) => handleChangeDate(date)} todayButton="Hoje" /> */}
                <DatePicker
                  className="ant-input w-[124px] text-lg text-red-500 font-bold "
                  selected={deliveryDate}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date: any) => dateChange(date)}
                  todayButton="Hoje"
                  popperPlacement="bottom"

                />
              </Form.Item>
            </div>
            {/* </Col>
            </Row> */}

            <div className="mb-4 font-semibold">Caixas para embalagem:</div>
            {formSaidaMercadoria.getFieldValue('itens') && formSaidaMercadoria.getFieldValue('caixas').map((caixa: any, index: number) => {
              return (
                <div key={index} className="flex justify-start font-medium mr-4">
                  {/* <Row className="linha" gutter={16}>
                  <Col className="coluna" span={18} > */}
                  <Form.Item className="my-0 w-[350px]"
                    name={caixa.id}
                    label={`${index + 1} - ${caixa.nomeComponente}`}
                    labelCol={{ span: 18 }}
                  >
                    <InputNumber className="myinputnumber text-lg text-red-500 font-bold" min={1} max={100} />
                  </Form.Item>
                  {/* </Col>
                  </Row> */}
                </div>
              )
            })}
          </Form>
        </div>
        <Space className="drawer-footer flex w-full justify-between">
          <Space className="flex w-full justify-start">
            <Button onClick={() => { hideDrawerFormPrepararEntrega() }} type="primary" icon={<FastBackwardOutlined />} />
            <Button onClick={hideDrawerFormPrepararEntrega}>Cancel</Button>
          </Space>
          <Button onClick={formSaidaMercadoria.submit} disabled={buttonsDisabled} type="primary">Submit</Button>
        </Space>
      </Drawer>
    </Layout>

  );
}