import { Button, Carousel } from 'antd';
import { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { useGeral } from '../../contexts/geral';
import { useNavigate } from "react-router-dom";
import Layout from '../../components/template/Layout';
import { apierp } from '../../services/api';

export default function HomeTenant() {

  const { user, Logout } = useAuth();
  const usuario: any = user
  // console.log(usuario)
  const { userTenant, rotaBase, permissions } = useGeral();
  // console.log(userTenant)
  // console.log(permissions)

  // const [itemsMenu, setItemsMenu] = useState([])
  let navigate = useNavigate();

  useEffect(() => {
    if (!usuario?.shortName) {
      navigate(`/login`);
    }

    // setShowMenu(false)
    // setMenuGrupoSelected(-1)
    // const [itemsMenu] = useState(usuario?.userMenu)

  }, [])

  async function gotoUsers() {
    const rota = rotaBase + "/users"
    navigate(rota);
  }

  const contentStyle: React.CSSProperties = {
    height: 'calc(100vh - 290px)',
    width:'calc(100%)',
    // color: '#fff',
    textAlign: 'center',
    objectFit: "contain"
  };

  return (
    <Layout
      titulo={"Página Principal"}
      subtitulo={"Bem vindo ao Zapp! - Selecione uma opção no menu ao lado"}
    >
      <div
        className="bg-blue-500 py-10 px-10" // (290-210)/2 = 40 = py-10
        style={{
          backgroundImage: 'url(/principal/fundo-min.png)',
          backgroundSize: 'cover',
          // backgroundImage: 'url(/images/gotafade2.png)',
          // backgroundSize: '139px 193px',
          height: 'calc(100vh - 210px)',

        }}
      >
        {/* <div className='flex items-center justify-center'>
          <img style={{
            height: "calc(100vh - 250px)",
            width: "calc(100% - 50px)",
            marginTop: " 20px",
            marginBottom: " 20px",
            objectFit: "contain"
          }}
            // src="https://source.unsplash.com/random/?interiors,orientation=landscape"
            src='/principal/sofa2-sujo.png'
            alt="Background"
          />
        </div> */}
        <Carousel className='' effect="fade" autoplay>

          {/* <div className=''>
            <img className='mx-auto ' style={contentStyle} src='/principal/sofa2-sujo.png' alt="Background" />
          </div>
          <div className=''>
            <img className='mx-auto ' style={contentStyle} src='/principal/sofa2-min.png' alt="Background" />
          </div>
          <div className=''>
            <img className='mx-auto ' style={contentStyle} src='/principal/carro-min7.png' alt="Background" />
          </div>
          <div className=''>
            <img className='mx-auto ' style={contentStyle} src='/principal/sofa-banner.png' alt="Background" />
          </div>
          <div className=''>
            <img className='mx-auto ' style={contentStyle} src='/principal/limpador-tecidos-colorbrilho.png' alt="Background" />
          </div>
          <div className=''>
            <img className='mx-auto ' style={contentStyle} src='/principal/POLTRONA-1024x816.webp' alt="Background" />
          </div>
          <div className=''>
            <img className='mx-auto ' style={contentStyle} src="https://source.unsplash.com/random/?sofá,orientation=landscape" alt="Background" />
          </div> */}

          {/* <div className=''>
            <img className='mx-auto ' style={contentStyle} src="https://source.unsplash.com/random/?fotografias,orientation=portrait" alt="Background" />
          </div> */}
          
          <div className=''>
            <img className='mx-auto ' style={contentStyle} src="https://source.unsplash.com/random/?interiors,orientation=landscape" alt="Background" />
          </div>
        </Carousel>

      </div>
    </Layout>
  )
}
