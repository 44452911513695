import * as React from "react"

import { useAuth } from "../../contexts/auth";

import Layout from "../../components/template/Layout";

import { Button, Typography } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
const { Title } = Typography;

// import { geraPaletas } from "../../utils/geraPaletas";

export default function Colors() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  React.useEffect(() => {
    // console.log(itemsMenu)
  }, []);

  // let colors = generate('#006C6C');
  // console.log(colors); // ['#E6F7FF', '#BAE7FF', '#91D5FF', ''#69C0FF', '#40A9FF', '#1890FF', '#096DD9', '#0050B3', '#003A8C', '#002766']

  const primeColors = ['surface', 'blue', 'green', 'yellow', 'cyan', 'pink', 'indigo', 'teal', 'orange', 'bluegray', 'purple']
  const antColors = ['red', 'volcano', 'orange', 'gold', 'yellow', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple', 'magenta', 'grey']
  const allWeights = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]

  function palettesPrime() {
    const abc: any = []
    // abc.push(<div className="flex align-center justify-content-start h-3rem w-full px-3 font-bold " style={{ backgroundColor: `var(--surface-0)` }}><div className="w-10rem flex">var(--surface-0)</div><div className="w-10rem flex">surface-0</div></div>)
    primeColors.forEach((bgcor: any) => {
      if (bgcor === 'surface') return
      allWeights.forEach((w: any) => {
        const cor = w > 500 ? '#fff' : '#000'
        const prfx = bgcor === 'surface' ? '' : 'bg-'
        abc.push(
          <div className="flex">
            <div className={`flex align-center justify-content-start h-3rem w-2 px-3 font-bold `} style={{ backgroundImage: "linear-gradient(rgba(255,255,255,0), rgba(0,0,0,.05))" }}>
              <div className={`w-full flex`} style={{ color: `var(--${bgcor}-${w})` }}>text-{bgcor}-{w}</div>
            </div>
            <div className={`flex align-center justify-content-start h-3rem w-10 px-3 font-bold bg-${bgcor}-${w} ${bgcor}-${w} `} style={{ color: cor, }}>
              <div className="w-6 flex">var(--{bgcor}-{w})</div>
              <div className="w-6 flex">{prfx}{bgcor}-{w}</div>
            </div>
          </div>
        )
      })
    })
    return abc
  }
  function palettesAnt() {
    const abc: any = []
    antColors.forEach((bgcor: any) => {
      allWeights.forEach((w: any) => {
        const cor = w > 500 ? '#fff' : '#000'
        const cord = w > 500 ? '#000' : '#fff'
        abc.push(
          <div className="flex">
            <div className={`flex align-center justify-content-start h-3rem w-2 px-3 font-bold `} style={{ backgroundImage: "linear-gradient(rgba(255,255,255,0), rgba(0,0,0,.05))" }}>
              <div className={`w-full flex`} style={{ color: `var(--ant-${bgcor}-${w}` }}>cor-ant-{bgcor}-{w}</div>
            </div>
            <div className={`flex align-center justify-content-start h-3rem w-4 px-3 font-bold bg-ant-${bgcor}-${w} `} style={{ color: cor }}>
              <div className={`w-6 flex`}>var(--ant-{bgcor}-{w})</div>
              <div className={`w-6 flex`}>bg-ant-{bgcor}-{w}</div>
            </div>
            <div className={`flex align-center justify-content-start h-3rem w-4 px-3 font-bold bg-ant-${bgcor}-dark-${w} `} style={{ color: cord }}>
              <div className={`w-6 flex`}>var(--ant-{bgcor}-dark-{w})</div>
              <div className={`w-6 flex`}>bg-ant-{bgcor}-dark-{w}</div>
            </div>
            <div className={`flex align-center justify-content-start h-3rem w-2 px-3 font-bold `} style={{ backgroundImage: "linear-gradient(rgba(255,255,255,0), rgba(0,0,0,.05))" }}>
              <div className={`w-full flex`} style={{ color: `var(--ant-${bgcor}-dark-${w}` }}>cor-ant-{bgcor}-dark-{w}</div>
            </div>
          </div>
        )
      })
    })
    return abc
  }

  // QUEBRA GALHO PARA GERAR CORES E CLASSES DE BG E COR DO '@ant-design/colors
  // console.log(geraPaletas())

  const wTab = 1113 // liquido = x-17

  return (
    <Layout titulo="PALETAS DE CORES">
      {/* <div className="mb-5" >
        <div className="flex flex-row justify-center items-center mx-auto " style={{ maxWidth: `${wTab}px`, minWidth: `${wTab}px` }}>
        <div className="w-full mx-5" >
            <div className="flex align-center h-8 px-3 text-xl font-bold bg-blue-900 text-blue-50 ">PrimeReact</div >
            <div className="flex align-center text-sm font-medium bg-gray-600 text-blue-50 ">
              <div className="flex  px-3">Class color</div>
              <div className="flex  px-3">
                <div className="flex ">Style</div>
                <div className="flex ">Class backgroundColor</div>
              </div>
            </div >
            {palettesPrime().map((color: any, i: number) =>
              color
            )}

            <div className="flex align-center h-8 px-3 text-xl mt-5 font-bold bg-blue-900 text-blue-50 ">Ant Design</div >
            <div className="flex align-center text-sm font-medium bg-gray-600 text-blue-50 ">
              <div className="flex w-2 px-3">Class color</div>
              <div className="flex w-4 px-3">
                <div className="flex w-6">Style</div>
                <div className="flex w-6">Class backgroundColor</div>
              </div>
              <div className="flex w-4 px-3">
                <div className="flex w-6">Style</div>
                <div className="flex w-6">Class backgroundColor</div>
              </div>
              <div className="flex w-2 px-3">Class color</div>
            </div >
            {palettesAnt().map((color: any, i: number) =>
              color
            )}
          </div>
        </div>
      </div> */}
    </Layout>

  );
}
